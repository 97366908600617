import {
    call, put, takeLatest
} from 'redux-saga/effects';
import * as c from './constants';
import { getUnioneToken, goToAuth } from '@unione-pro/unione.commons.webpack';
import { serverErrorsLogger } from '../../utils/server-errors-logger';
import { setUser } from './constants';
import { getUserInfo, setMainUser2 } from './api';


function* getUserSaga() {
    const { success, failure } = setUser;
    try {
    const token = yield call(getUnioneToken);
        if (!token) {
            goToAuth();
        } else {
            const { data } = yield call(getUserInfo, token);
            yield put({ type: success, payload: data });
        }
    } catch (e) {
        goToAuth();
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}

function* setMainUser2OdataSaga(id, body) {
    const { success, failure } = c.setMainUser2;
    try {
        yield call(setMainUser2, id, body);
        yield put({ type: success });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}

export default function* rootSaga() {
    yield takeLatest(setUser.tries, getUserSaga);
    yield takeLatest(c.setMainUser2.tries, setMainUser2OdataSaga);
}
