import axios from 'axios';
import Cookies from 'js-cookie';

// const statHost = 'https://bestdedik.ru';
const statHost = process.env.REACT_APP_BACKEND_STAT_SERVISE;

export type StatInfo = {
    moduleName: string;
    name: string
    direction: string
}


export const stat = (opopId : string, data: StatInfo) => axios.post(`${statHost}/stat/sync/${opopId}`, data, {
    headers: {
        authorization: `${Cookies.get('_utid')}`,
        'Content-Type': 'application/json'
    }
});

export const statistic = (data) => axios.post(`${statHost}/stat/push`, data, {
    headers: {
        authorization:`${Cookies.get('_utid')}`,
        'Content-Type': 'application/json'
    }
})
