import React, { Children, cloneElement } from 'react';
import bem from 'easy-bem';

import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, Form } from 'antd';
import { ButtonV2 } from 'components';
import { useTranslation } from 'react-i18next';

import './style.less';
import DeleteBtn from 'components/delete-btn';


const OOCGenBlock = (props) => {
    const b = bem('ooc-gen-block');
    const { t } = useTranslation('dev-educational-program');

    const {
        blockFieldName,
        blockName,
        data,
        children,
        addText,
        initialValueAdd,
        deleteIcon,
        ...restField
    } = props;

    const generateChildrenWithNewProps = ({ children, name }) => Children.map(children, (element) => {
        if (element?.props?.name) {
            const newProps = {
                ...element.props,
                name: [...element.props.name],
                ...restField
            };
            newProps.name[0] = name;

            return cloneElement(
                element,
                newProps,
                element.props.children
            );
        }
        if (!element?.props) {
            return cloneElement(
                <span>{element}</span>
            );
        }

        return cloneElement(
            element,
            element.props,
            generateChildrenWithNewProps({
                children: Children.toArray(Array.isArray(element?.props?.children)
                    ? element.props.children
                    : [element.props.children]), name
            })
        );
    });

    return (
        <div className={b()}>
            <Form.List name={[blockFieldName, blockName]}>
                {(fields, { add, remove }) => (
                    <>
                        <div className="form-items-list">
                            {fields.map((field) => {
                                const {
                                    key, name
                                } = field;

                                return (
                                    <div className={`${b('activity-item')} form-items-group`} key={key}>
                                        <Row
                                            gutter={4}
                                            align="bottom"
                                            wrap={false}
                                        >
                                            <Col flex="1">
                                                {generateChildrenWithNewProps({ children, name })}
                                            </Col>
                                            <Col>
                                                <Form.Item>
                                                    <DeleteBtn
                                                        icon={deleteIcon ? '' : 'cross'}
                                                        onClick={() => remove(name)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            })}
                        </div>
                        <Row justify="end">
                            <Form.Item>
                                <ButtonV2
                                    type="link"
                                    onClick={() => add(initialValueAdd || {})}
                                    icon={<PlusOutlined />}
                                >
                                    {addText || t('add-more')}
                                </ButtonV2>
                            </Form.Item>
                        </Row>
                    </>
                )}
            </Form.List>
        </div>
    );
};

export default OOCGenBlock;
export { OOCGenBlock as GenBlock };
