import React, { useMemo } from 'react';
import bem from 'easy-bem';
import {
    Form, Col, Row, AutoComplete
} from 'antd';
import Select from 'components/select';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import InputV2 from 'components/input-v2';
import DeleteBtn from 'components/delete-btn';
import ButtonV2 from 'components/button-v2';
import { GenBlock } from 'components/generated-block/generatedBlock';
import { useSelector } from 'react-redux';
import { useXlsxParser } from 'utils/excel-parse';
import useDebouncedCallback from 'utils/use-debounce-callback';


const tabKey = '1.4';

const ResultsUniversalCompFormBlock = ({ universalCompEmptyObj, form }) => {
    const b = bem('results-universal-comp-form-block');
    const { t } = useTranslation('dev-educational-program');
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey] || universalCompEmptyObj);
    
    const xlsxParser = useXlsxParser();
    const xlsxLoaded = xlsxParser?.isLoaded();

    const listUk = useMemo(() => xlsxLoaded ? xlsxParser.getNamesCategoryUK() : [], [xlsxLoaded]);

    const onCategoryChange = useDebouncedCallback((value, target, index) => {
        try {
            const details = { ...tabDetails };
            const { name, index: childIndex, parentIndex } = target;
            details.universalComp[index].name = value;
            details.universalComp[index].nameUc = name;
            const list = xlsxParser.getCodeAndNameOfIndicator(value, childIndex, parentIndex);
            details.universalComp[index].list = list?.length ? list : universalCompEmptyObj.list;
            form.setFieldsValue(details);
        } catch (err) {
            console.log(err);
        }
    }, 500);

    return (
        <section className={b()}>
            <h5 className="subtitle">
                {`${t('block-1')}: ${t('universal-comp')}`}
            </h5>
            <Form.List name="universalComp">
                {(fields, { add, remove }) => (
                    <>
                        <div className="form-items-list">
                            {fields.map(({
                                key, name, ...restField
                            }, index) => (
                                <div className={`${b('comp-item')} form-items-group`} key={key}>
                                    <Row
                                        gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                        align="bottom"
                                        wrap={false}
                                    >
                                        <Col flex="1">
                                            <div className="item-block-group">
                                                <Row>
                                                    <Col flex="1">
                                                        <Form.Item
                                                            {...restField}
                                                            label={t('category-group-name')}
                                                            name={[name, 'name']}
                                                        >
                                                            <AutoComplete
                                                                className="form-select"
                                                                placeholder={t('enter-category-name')}
                                                                size="large"
                                                                onChange={(value, target) => onCategoryChange(value, target, index)}
                                                                showSearch
                                                                options={listUk}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col flex="1">
                                                        <Form.Item
                                                            {...restField}
                                                            label={t('universal-comp-code-uc-name', {
                                                                index: name + 1
                                                            })}
                                                            name={[name, 'nameUc']}
                                                        >
                                                            <InputV2
                                                                textarea
                                                                autoSize={{ minRows: 3, maxRows: 6 }}
                                                                placeholder={t('enter-uc-name')}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <p className="item-label">
                                                    {t('uc-achievement-indicator-сode-and-name')}
                                                </p>
                                                <div className="item-block-group-3">
                                                    <GenBlock
                                                        blockFieldName={name}
                                                        blockName="list"
                                                        initialValueAdd={universalCompEmptyObj.list[0]}
                                                        {...restField}
                                                    >
                                                        <Row
                                                            gutter={[32, 8]}
                                                            justify="space-between"
                                                        >
                                                            <Col flex="136px">
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'indicatorCode']}
                                                                >
                                                                    <InputV2
                                                                        placeholder={t('enter-uc-indicator-code')}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col flex="1 0 247px">
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'indicatorName']}
                                                                >
                                                                    <InputV2
                                                                        textarea
                                                                        autoSize={{ minRows: 2, maxRows: 3 }}
                                                                        placeholder={t('enter-uc-indicator-name')}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </GenBlock>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <Form.Item>
                                                <DeleteBtn
                                                    onClick={() => remove(name)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                        <Form.Item>
                            <ButtonV2
                                type="link"
                                onClick={() => add(universalCompEmptyObj)}
                                icon={<PlusOutlined />}
                            >
                                {t('add-more-block')}
                            </ButtonV2>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </section>
    );
};

export default ResultsUniversalCompFormBlock;
