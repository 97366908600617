/* eslint-disable max-len */
import React, { useEffect, useMemo } from 'react';
import bem from 'easy-bem';
import {
    Col, Divider, Form, Radio, Row, AutoComplete,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select from 'components/select';
import useDebouncedCallback from 'utils/use-debounce-callback';
import { PlusOutlined } from '@ant-design/icons';
import ButtonV2 from 'components/button-v2';
import DeleteBtn from 'components/delete-btn';
import InputV2 from 'components/input-v2';
import Next from 'components/next';
import { Link } from 'react-router-dom';
import { updateTabDetails } from 'utils/dispatches';
import { previewDoc } from '../../utils';
import { emptyDocDetails } from '../../constants';
import { useXlsxParser } from 'utils/excel-parse';


const tabKey = '5.5';
const emptyObj = emptyDocDetails[tabKey];

const getNewDisciplineCredits = (hoursValue = 0, disciplineHours = 0) => (hoursValue > 0 && disciplineHours !== null
    ? +disciplineHours / hoursValue
    : null);

const negativeNumberRules = {
    rules: [
        { type: 'number', message: 'Значение не может быть отрицательным', min: 0 },
    ],
};

const declineCredits = (credits) => {
    if (credits === 1) return 'зачетная единица';
    if (credits > 1 && credits < 5) return 'зачетные единицы';
    return 'зачетных единиц';
};

const calculateTabDetails = (
    { sizeByTypes = [], disciplineHours = 0 },
    hoursValue = 0
) => {
    const newSizeByTypes = sizeByTypes.map((sizeByTypesItem) => {
        const {
            selfLearningTheory,
            selfLearningHomework,
            selfLearningCoursework,
            selfLearningOther,
            practicesHours,
            colloquiumHours,
            labsHours,
            otherHours,
            lecturesHours,
            otherAuditHours,
            nonAuditHours,
            intermediateAttestation,
        } = sizeByTypesItem;
        const independentWork = typeof selfLearningTheory === 'number'
      || typeof selfLearningHomework === 'number'
      || typeof selfLearningCoursework === 'number'
      || typeof selfLearningOther === 'number'
            ? +selfLearningTheory
          + +selfLearningHomework
          + +selfLearningCoursework
          + +selfLearningOther
            : '';
        const seminarTypeStudy = typeof practicesHours === 'number'
      || typeof colloquiumHours === 'number'
      || typeof labsHours === 'number'
      || typeof otherHours === 'number'
            ? +practicesHours + +colloquiumHours + +labsHours + +otherHours
            : '';
        const contactWorkAudit = typeof lecturesHours === 'number'
      || typeof seminarTypeStudy === 'number'
      || typeof otherAuditHours === 'number'
            ? +lecturesHours + +seminarTypeStudy + +otherAuditHours
            : '';
        const contactWork = typeof contactWorkAudit === 'number' || typeof nonAuditHours === 'number'
            ? +contactWorkAudit + +nonAuditHours
            : '';
        const disciplineModuleVolume = typeof independentWork === 'number'
            || typeof intermediateAttestation === 'number'
            || typeof contactWork === 'number' ? +contactWork + +independentWork + +intermediateAttestation
            : '';

        return {
            ...sizeByTypesItem,
            contactWorkAudit,
            seminarTypeStudy,
            independentWork,
            contactWork,
            disciplineModuleVolume,
        };
    });

    const semestersSet = new Set();
    const calculatedSizeByTypes = newSizeByTypes.reduce(
        (accObj, currentBlock) => {
            semestersSet.add(currentBlock.semester);

            return Object.keys(emptyObj.calculatedSizeByTypes).reduce(
                (acc, prop) =>
                // todo: replace emptyObj with current keys
                    ({
                        ...acc,
                        [prop]: {
                            ...accObj[prop],
                            [currentBlock.semester]: currentBlock.semester
                                ? (+accObj[prop]?.[currentBlock.semester] || 0)
                  + +currentBlock[prop]
                                : '',
                            total: (+accObj[prop]?.total || 0) + +currentBlock[prop],
                        },
                    }),
                {}
            );
        },
        {}
    );

    return {
        sizeByTypes: newSizeByTypes,
        semesters: Array.from(semestersSet),
        calculatedSizeByTypes,
        disciplineCredits: getNewDisciplineCredits(hoursValue, disciplineHours),
    };
};

const WorkDisciplineSizeTab = ({ onNextClick }) => {
    const b = bem('work-discipline-size-tab');
    const { t } = useTranslation('dev-educational-program');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabDetails = useSelector(
        (state) => state.documents.documentDetails?.[tabKey]
    );
    const { disciplineHours = null, disciplineCredits = 0 } = tabDetails || {};
    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);
    const xlsxParser = useXlsxParser();
    const xlsxLoaded = xlsxParser?.isLoaded();
    const rpdList = useSelector((state) => state.documents.rpdList || []);
    const disciplineCodeAndName = useSelector(
        (state) => state.documents.documentDetails?.[5.1]?.disciplineCodeAndName
    );
    const currentRpdIndex = useSelector(
        (state) => state.documents.currentRpdIndex
    );

    const intermediateAttestationFormList = useMemo(() => {
        if (xlsxLoaded) {
            return xlsxParser?.getFormOfIntermediateCertification?.(disciplineCodeAndName) || [];
        }
        return [];
    }, [disciplineCodeAndName, xlsxLoaded]);

    const { duration: { durationInYears = 0, durationInMonths = 0 } = {} } = useSelector((state) => state.documents.documentDetails?.['1.2'] || {});
    const courseMaxNumber = useMemo(
        () => Math.ceil(+durationInYears + durationInMonths / 12),
        [durationInMonths, durationInYears]
    );

    const { hoursValue = 0 } = useSelector(
        (state) => state.documents.documentDetails?.['1.5'] || {}
    );
      
    useEffect(() => {
        updateTabDetails(
            tabKey,
            {
                ...tabDetails,
                disciplineCredits: getNewDisciplineCredits(hoursValue, disciplineHours),
            },
            dispatch
        );
    }, [hoursValue]);

    const onValuesChange = useDebouncedCallback((changed, all) => {
        const newTabDetails = {
            ...all,
            ...calculateTabDetails(all, hoursValue),
        };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    return (
        <div className={b()}>
            <div className="constructor-form">
                <h4 className="title">{t('work-discipline-size')}</h4>
                <h4 className="program-title-name">
                    {currentRpdIndex === -1
                        ? ''
                        : `${t('rpd')}: ${
                            rpdList[currentRpdIndex]?.[5.1]?.disciplineCodeAndName
                        }`}
                </h4>
                <Form
                    form={form}
                    initialValues={initialValues}
                    onValuesChange={onValuesChange}
                    layout="vertical"
                >
                    <section>
                        <Row gutter={32}>
                            <Col>
                                <p className="item-label item-label-big">
                                    Общий объем дисциплины (модуля) составляет
                                </p>
                            </Col>
                            <Col flex="0 1 154px">
                                <Form.Item
                                    name="disciplineHours"
                                    extra={
                                        hoursValue > 0 ? (
                                            disciplineHours >= 0
                                            && disciplineCredits !== null
                                            && `${disciplineCredits} ${declineCredits(
                                                disciplineCredits
                                            )}`
                                        ) : (
                                            <span>
                                                Предварительное необходимо указать объем программы в
                                                разделе
                                                <Link
                                                    to={(location) => ({
                                                        ...location,
                                                        hash: '#1.5',
                                                    })}
                                                >
                                                    {` "${t(
                                                        'educational-programs-structure-and-scope'
                                                    )}" `}
                                                </Link>
                                                (модуль "Общая характеристика ОПОП")
                                            </span>
                                        )
                                    }
                                    {...negativeNumberRules}
                                >
                                    <InputV2
                                        number
                                        placeholder="часов"
                                        disabled={hoursValue <= 0}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </section>
                    <Divider />

                    <section>
                        <h5 className="subtitle">
                            Объем дисциплины (модуля) по видам учебной работы, учебных занятий
                            и самостоятельной работы по очной форме обучения
                        </h5>
                        <Form.List name="sizeByTypes">
                            {(fields, { add, remove }) => (
                                <>
                                    <div className="form-items-list">
                                        {fields.map(({ key, name, ...restField }) => (
                                            <div className={`${b('task-item')} form-item`} key={key}>
                                                <Row
                                                    gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                                    align="bottom"
                                                    wrap={false}
                                                >
                                                    <Col flex="1">
                                                        <div className="item-block-group">
                                                            <Row gutter={32}>
                                                                <Col span={24}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'course']}
                                                                        label="Выберите курс"
                                                                    >
                                                                        {courseMaxNumber > 0 ? (
                                                                            <Radio.Group>
                                                                                {Array.from(
                                                                                    { length: courseMaxNumber },
                                                                                    (_, i) => (
                                                                                        <Radio value={i + 1}>
                                                                                            {`${i + 1} курс`}
                                                                                        </Radio>
                                                                                    )
                                                                                )}
                                                                            </Radio.Group>
                                                                        ) : (
                                                                            <span>
                                                                                {`${t('fill-in-field-first')} 
                                                                                    "${t(
                                                                                'ed-duration'
                                                                            )}" 
                                                                                    (${t(
                                                                                'module'
                                                                            )} 
                                                                                    "${t(
                                                                                'opop-general-characteristics'
                                                                            )}" 
                                                                                    ${t(
                                                                                'chapter'
                                                                            )} `}
                                                                                <Link
                                                                                    to={(location) => ({
                                                                                        ...location,
                                                                                        hash: '#1.2',
                                                                                    })}
                                                                                >
                                                                                    {t('general-info')}
                                                                                </Link>
                                                                            </span>
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={32}>
                                                                <Col flex="0 0 98px">
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'semester']}
                                                                        label="Семестр"
                                                                    >
                                                                        <InputV2 number />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Divider />

                                                            <p className="block-header">
                                                                Контактная работа (аудиторная):
                                                                {' '}
                                                                {tabDetails?.sizeByTypes?.[key]
                                                                    ?.contactWorkAudit || 0}
                                                                {' '}
                                                                часов
                                                            </p>

                                                            <Row gutter={32}>
                                                                <Col span={16}>
                                                                    <p className="block-main-text">лекции</p>
                                                                </Col>
                                                                <Col span={4}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'lecturesHours']}
                                                                        {...negativeNumberRules}
                                                                    >
                                                                        <InputV2 number placeholder="часов" />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>

                                                            <p className="block-main-text">
                                                                занятия семинарского типа:
                                                            </p>
                                                            <Row gutter={32}>
                                                                <Col span={15} offset={1}>
                                                                    <p>
                                                                        практические занятия, семинары, практикумы
                                                                    </p>
                                                                </Col>
                                                                <Col span={4}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'practicesHours']}
                                                                        {...negativeNumberRules}
                                                                    >
                                                                        <InputV2 number placeholder="часов" />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={32}>
                                                                <Col span={15} offset={1}>
                                                                    <p>коллоквиумы</p>
                                                                </Col>
                                                                <Col span={4}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'colloquiumHours']}
                                                                        {...negativeNumberRules}
                                                                    >
                                                                        <InputV2 number placeholder="часов" />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={32}>
                                                                <Col span={15} offset={1}>
                                                                    <p>
                                                                        лабораторные работы, лабораторные практикумы
                                                                    </p>
                                                                </Col>
                                                                <Col span={4}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'labsHours']}
                                                                        {...negativeNumberRules}
                                                                    >
                                                                        <InputV2 number placeholder="часов" />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={32}>
                                                                <Col span={15} offset={1}>
                                                                    <p>другие формы аудиторных занятий</p>
                                                                </Col>
                                                                <Col span={4}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'otherHours']}
                                                                        {...negativeNumberRules}
                                                                    >
                                                                        <InputV2 number placeholder="часов" />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={32}>
                                                                <Col span={16}>
                                                                    <p className="block-main-text">
                                                                        другие виды контактной (аудиторной) работы
                                                                    </p>
                                                                </Col>
                                                                <Col span={4}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'otherAuditHours']}
                                                                        {...negativeNumberRules}
                                                                    >
                                                                        <InputV2 number placeholder="часов" />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Divider />

                                                            <Row gutter={32}>
                                                                <Col span={16}>
                                                                    <p className="block-header">
                                                                        Контактная работа (внеаудиторная)
                                                                    </p>
                                                                </Col>
                                                                <Col span={4}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'nonAuditHours']}
                                                                        {...negativeNumberRules}
                                                                    >
                                                                        <InputV2 number placeholder="часов" />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Divider />

                                                            <p className="block-header">
                                                                Самостоятельная работа обучающихся:
                                                                {' '}
                                                                {`${tabDetails?.sizeByTypes?.[key]?.independentWork} часов`
                                  || '0 часов'}
                                                            </p>

                                                            <Row gutter={32}>
                                                                <Col span={16}>
                                                                    <p className="block-main-text">
                                                                        изучение теоретического материала,
                                                                        подготовка к занятиям
                                                                    </p>
                                                                </Col>
                                                                <Col span={4}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'selfLearningTheory']}
                                                                        {...negativeNumberRules}
                                                                    >
                                                                        <InputV2 number placeholder="часов" />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={32}>
                                                                <Col span={16}>
                                                                    <p className="block-main-text">
                                                                        выполнение домашних заданий (РГР, решение
                                                                        задач, реферат, эссе и другое)
                                                                    </p>
                                                                </Col>
                                                                <Col span={4}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'selfLearningHomework']}
                                                                        {...negativeNumberRules}
                                                                    >
                                                                        <InputV2 number placeholder="часов" />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={32}>
                                                                <Col span={16}>
                                                                    <p className="block-main-text">
                                                                        выполнение курсовой работы/курсовое
                                                                        проектирование
                                                                    </p>
                                                                </Col>
                                                                <Col span={4}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'selfLearningCoursework']}
                                                                        {...negativeNumberRules}
                                                                    >
                                                                        <InputV2 number placeholder="часов" />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={32}>
                                                                <Col span={16}>
                                                                    <p className="block-main-text">
                                                                        другие виды самостоятельной работы
                                                                    </p>
                                                                </Col>
                                                                <Col span={4}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'selfLearningOther']}
                                                                        {...negativeNumberRules}
                                                                    >
                                                                        <InputV2 number placeholder="часов" />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Divider />

                                                            <Row gutter={32}>
                                                                <Col span={16}>
                                                                    <p className="block-header">
                                                                        Промежуточная аттестация
                                                                    </p>
                                                                </Col>
                                                                <Col span={4}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'intermediateAttestation']}
                                                                        {...negativeNumberRules}
                                                                    >
                                                                        <InputV2 number placeholder="часов" />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Divider />

                                                            <Row gutter={32}>
                                                                <Col span={16}>
                                                                    <p className="block-header">
                                                                        Общий объем дисциплины (модуля) в форме
                                                                        практической подготовки
                                                                    </p>
                                                                </Col>
                                                                <Col span={4}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'disciplineSize']}
                                                                        {...negativeNumberRules}
                                                                    >
                                                                        <InputV2 number placeholder="часов" />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Divider />

                                                            <Row gutter={32}>
                                                                <Col span={24}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        label="Форма(ы) промежуточной аттестации"
                                                                        name={[name, 'intermediateAttestationForm']}
                                                                    >
                                                                        <AutoComplete
                                                                            className="form-select"
                                                                            placeholder="Введите форму(ы)"
                                                                            size="large"
                                                                            showSearch
                                                                            options={intermediateAttestationFormList}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <Form.Item>
                                                            <DeleteBtn onClick={() => remove(name)} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <ButtonV2
                                                type="link"
                                                onClick={() => add(emptyObj.sizeByTypes[0])}
                                                icon={<PlusOutlined />}
                                            >
                                                Добавить еще дисциплину
                                            </ButtonV2>
                                        </Form.Item>
                                    </div>
                                </>
                            )}
                        </Form.List>
                    </section>
                    <Divider />

                    <Next
                        onPreview={() => previewDoc(
                            tabKey,
                            {
                                ...emptyObj,
                                ...tabDetails,
                                ...calculateTabDetails(tabDetails, hoursValue),
                            },
                            dispatch
                        )}
                        onNextClick={() => onNextClick(form)}
                    />
                </Form>
            </div>
        </div>
    );
};

export default WorkDisciplineSizeTab;
