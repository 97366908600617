/* eslint-disable max-len */
import {
    call, put, takeLatest
} from 'redux-saga/effects';
import { getUnioneToken } from '@unione-pro/unione.commons.webpack';
import * as c from './constants';
import {
    getDocsListByUserId,
    getDocsList,
    getDirectoryListMock,
    createDoc,
    updateDocById,
    setMainUser,
    removeDocById,
    getDocById,
    createAcademicPlan,
    removeAcademicPlanById,
    uploadStudyPlan,
    updateDocUsersById
} from './api';
import { docsListToClientOData } from './converters';
import { serverErrorsLogger } from '../../utils/server-errors-logger';


function* getDocsListUserIdSaga({ payload }) {
    const { success, failure } = c.getDocsListByUserId;

    try {
    const token = yield call(getUnioneToken);

        const rawData = yield call(getDocsListByUserId, payload, token);
        const data = docsListToClientOData(rawData);
        yield put({ type: success, payload: data });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}

function* getDocsListSaga(action) {
    const { success, failure } = c.getDocsList;
    try {
        const { perPage, page } = action.payload;
        const rawOrgs = yield call(
            getDocsList, perPage, page
        );
        const payload = docsListToClientOData(rawOrgs);
        yield put({ type: success, payload });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}


function* getDirectorySaga() {
    const { success, failure } = c.getDirectoryListMock;

    try {
        const data = yield call(getDirectoryListMock);
        yield put({ type: success, payload: { directoryList: data } });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}


function* createDocOdataSaga(body) {
    const { success, failure } = c.createDoc;

    try {
        const data = yield call(createDoc, body);
        yield put({ type: success, payload: data });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}

function* updateDocByIdOdataSaga(id, body) {
    const { success, failure } = c.updateDocById;
    try {
        yield call(updateDocById, id, body);
        yield put({ type: success });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}

function* setMainUserOdataSaga(id, body) {
    const { success, failure } = c.setMainUser;
    try {
        yield call(setMainUser, id, body);
        yield put({ type: success });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}

function* updateDocUsersByIdOdataSaga({ payload }) {
    const { success, failure } = c.updateDocUsersById;
    try {
        yield call(updateDocUsersById, payload);
        yield put({ type: success, payload });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}

function* removeDocByIdOdataSaga(id) {
    const { success, failure } = c.removeDocById;

    try {
        yield call(removeDocById, id);
        yield put({ type: success, payload: id });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}

function* getDocByIdOdataSaga(id) {
    const { success, failure } = c.getDocById;
    try {
    const token = yield call(getUnioneToken);

        const data = yield call(getDocById, id, token);
        yield put({ type: success, payload: data });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}

function* setUploadStudySaga({ payload }) {
    const { success, failure } = c.setUploadStudyPlan;
    try {
        yield call(uploadStudyPlan, payload);
        yield put({ type: success });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}

function* createAcademicPlanOdataSaga({ payload }) {
    const { success, failure } = c.createAcademicPlan;
    try {
        const data = yield call(createAcademicPlan, payload);
        yield put({ type: success, payload: data });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, paylaod: { message: e.message } });
    }
}

function* removeAcademicPlanSaga({ payload }) {
    const { success, failure } = c.removeAcademicPlan;
    try {
        const data = yield call(removeAcademicPlanById, payload);
        yield put({ type: success, payload: data });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, paylaod: { message: e.message } });
    }
}

export default function* rootSaga() {
    yield takeLatest(c.getDirectoryListMock.tries, getDirectorySaga);
    yield takeLatest(c.getDocsListByUserId.tries, getDocsListUserIdSaga);
    yield takeLatest(c.createDoc.tries, createDocOdataSaga);
    yield takeLatest(c.getDocsList.tries, getDocsListSaga);
    yield takeLatest(c.updateDocById.tries, updateDocByIdOdataSaga);
    yield takeLatest(c.setMainUser.tries, setMainUserOdataSaga);
    yield takeLatest(c.updateDocUsersById.tries, updateDocUsersByIdOdataSaga);
    yield takeLatest(c.getDocById.tries, getDocByIdOdataSaga);
    yield takeLatest(c.removeDocById.tries, removeDocByIdOdataSaga);
    yield takeLatest(c.setUploadStudyPlan.tries, setUploadStudySaga);
    yield takeLatest(c.createAcademicPlan.tries, createAcademicPlanOdataSaga);
    yield takeLatest(c.removeAcademicPlan.tries, removeAcademicPlanSaga);
}
