import React, { useMemo } from 'react';
import bem from 'easy-bem';
import {
    Form, Col, Row, AutoComplete
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Select from 'components/select';
import InputV2 from 'components/input-v2';
import ButtonV2 from 'components/button-v2';
import DeleteBtn from 'components/delete-btn';
import { GenBlock } from 'components/generated-block/generatedBlock';
import { useXlsxParser } from 'utils/excel-parse';


const ActivityTasksFormBlock = ({ activityTasksEmptyObj }) => {
    const b = bem('activity-tasks-form-block');
    const { t } = useTranslation('dev-educational-program');
    const xlsxParser = useXlsxParser();
    const xlsxLoaded = xlsxParser?.isLoaded();

    const listOfProfTasks = useMemo(() => {
        if (!xlsxLoaded) return;
        return xlsxParser?.getProfActivity?.() || [];
    }, [xlsxLoaded]);

    return (
        <section className={b()}>
            <h5 className="subtitle">
                {`${t('block-2')}: ${t('activity-prof-tasks')}`}
            </h5>
            <Form.List name="activityTasks">
                {(fields, { add, remove }) => (
                    <>
                        <div className="form-items-list">
                            {fields.map(({
                                key, name, ...restField
                            }) => (
                                <div className="activity-item form-items-group" key={key}>
                                    <Row
                                        gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                        align="bottom"
                                        wrap={false}
                                    >
                                        <Col flex="1">
                                            <div className="item-block-group">
                                                <Row
                                                    gutter={[{ sm: 16, md: 24, lg: 32 }, 16]}
                                                    wrap
                                                >
                                                    <Col span={24}>
                                                        <Form.Item
                                                            label={t('activity-prof-task-type')}
                                                            name={[name, 'type']}
                                                            {...restField}
                                                        >
                                                            <AutoComplete
                                                                className="form-select"
                                                                placeholder={t('enter-activity-prof-task-type')}
                                                                size="large"
                                                                showSearch
                                                                options={listOfProfTasks}
                                                            />                                                            
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <p className="item-label">
                                                            {t('activity-prof-task')}
                                                        </p>
                                                        <GenBlock
                                                            blockFieldName={name}
                                                            blockName="tasks"
                                                            initialValueAdd={
                                                                activityTasksEmptyObj?.tasks?.[0]
                                                            }
                                                            {...restField}
                                                        >
                                                            <Form.Item
                                                                name={[name, 'name']}
                                                                {...restField}
                                                            >
                                                                <InputV2
                                                                    placeholder={t('enter-activity-prof-task')}
                                                                />
                                                            </Form.Item>
                                                        </GenBlock>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col>
                                            <Form.Item>
                                                <DeleteBtn
                                                    onClick={() => remove(name)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                        <Form.Item>
                            <ButtonV2
                                type="link"
                                onClick={() => add(activityTasksEmptyObj)}
                                icon={<PlusOutlined />}
                            >
                                {t('add-more-activity-prof-tasks')}
                            </ButtonV2>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </section>
    );
};

export default ActivityTasksFormBlock;
