import React, { useEffect, useMemo, useRef } from 'react';
import bem from 'easy-bem';
import {
    Form, Divider, Radio, Col, Row
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import ButtonV2 from 'components/button-v2';
import InputV2 from 'components/input-v2';
import useDebouncedCallback from 'utils/use-debounce-callback';
import {
    setCurrentDocDetails,
    setCurrentRpdIndex,
    setRpdList,
} from 'models/documents/actions';
import _pick from 'lodash/pick';
import uniqueId from 'utils/uniqueId';
import Next from 'components/next';
import DeleteBtn from 'components/delete-btn';
import { updateTabDetails } from 'utils/dispatches';
import dayjs from 'dayjs';
import Select from 'components/select';
import expandArr from 'utils/expandArr';
import { importDocDetails, previewDoc } from '../../utils';
import { emptyDocDetails } from '../../constants';
import { useHistory } from 'react-router-dom';
import { useXlsxParser } from 'utils/excel-parse';


const tabKey = '5.1';
const emptyObj = emptyDocDetails[tabKey];

const stringRules = {
    rules: [{ type: 'string', required: true, message: 'Поле обязательно' }],
};

const WorkTitleTab = ({ onNextClick, setFormData }) => {
    const b = bem('work-title-tab');
    const { t } = useTranslation('dev-educational-program');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const history = useHistory();
    const moduleDetails = {
        5.1: useSelector((state) => state.documents.documentDetails?.['5.1']),
        5.2: useSelector((state) => state.documents.documentDetails?.['5.2']),
        5.3: useSelector((state) => state.documents.documentDetails?.['5.3']),
        5.4: useSelector((state) => state.documents.documentDetails?.['5.4']),
        5.5: useSelector((state) => state.documents.documentDetails?.['5.5']),
        5.6: useSelector((state) => state.documents.documentDetails?.['5.6']),
        5.7: useSelector((state) => state.documents.documentDetails?.['5.7']),
        5.8: useSelector((state) => state.documents.documentDetails?.['5.8']),
        5.9: useSelector((state) => state.documents.documentDetails?.['5.9']),
        '5.10': useSelector((state) => state.documents.documentDetails?.['5.10']),
    };
    const tabDetails = moduleDetails[tabKey];

    const initialValues = tabDetails || emptyObj;
    const importedTabDetails = {
        1.1: useSelector((state) => state.documents.documentDetails?.['1.1'] || {}),
    };
    const currentDoc = useSelector((state) => state.documents.documentDetails);
    const rpdData = useRef(tabDetails);
    const rpdList = useSelector((state) => state.documents.rpdList);
    const compCommon = useSelector((state) => state.documents.documentDetails?.['2.0']?.competenceCommon);
    const compProf = useSelector((state) => state.documents.documentDetails?.['2.0']?.competenceProf);
    
    // const getDisciplines = () => {
    //     const disc = [];
    //     for (let i = 0; i < compCommon?.length; i++) {
    //         compCommon?.[i]?.disciplinesForStudying.map((item) => {
    //             disc.push(item.disciplines);
    //             return;
    //         });
    //     }
    //     for (let i = 0; i < compProf?.length; i++) {
    //         compProf?.[i]?.disciplinesForStudying.map((item) => {
    //             disc.push(item.discipline);
    //             return;
    //         });
    //     }
    //     const uniqDesc = uniq(disc);
    //     return uniqDesc?.map((value) => ({ value, label: value })) || [];
    // };
    const currentRpdIndex = useSelector(
        (state) => state.documents.currentRpdIndex
    );

    const xlsxParser = useXlsxParser();
    const xlsxLoaded = xlsxParser.isLoaded();

    const codeAndNameDisciplineModule = useMemo(() => {
        if (xlsxLoaded) {
            return xlsxParser.getCodeAndNameDisciplineModule() || [];
        }
        return [];
    }, [xlsxLoaded]);

    const query = useMemo(
        () => new URLSearchParams(history.location.search),
        [history.location.search]
    );

    useEffect(() => {
    // onComponentDidMount
        form.setFieldsValue(tabDetails);
        setFormData(form);
        updateTabDetails(tabKey, initialValues, dispatch);
    }, []);

    useEffect(() => {
        const rpdId = query.get('rpdId');
        if (currentRpdIndex === -1 &&
            tabDetails?.disciplineCodeAndName?.length > 0 &&
            !rpdId
        ) {
            const newRpdList = {
                ...emptyObj,
                ...rpdData.current,
                status: 'draft',
                id: uniqueId(),
                [tabKey]: { ...rpdData.current },
                ...importDocDetails(importedTabDetails, tabKey),
                updatedAt: dayjs().format(),
            };
            dispatch(setRpdList([...rpdList, { ...newRpdList }]));
            dispatch(setCurrentRpdIndex(rpdList?.length));
        }
    }, [tabDetails]);

    const onValuesChange = useDebouncedCallback((changed, all) => {
        const newTabDetails = {
            ...all,
            disciplineCodeAndName: all?.disciplineCodeAndName?.split(' ').filter((word) => word !== '').join(' ')
        };
        updateTabDetails(tabKey, newTabDetails, dispatch);
        rpdData.current = tabDetails;
    }, 500);

    useEffect(() => {
        if (!xlsxLoaded || !tabDetails?.disciplineCodeAndName) {
            return;
        }
        const { disciplineCodeAndName } = tabDetails;
        const fileType = xlsxParser.getFileType();
        let sizeByTypes = [];
        let semesters = [];
        if (fileType === 'excel') {
            const course = xlsxParser?.getCoursesAndSemesters(disciplineCodeAndName);
            sizeByTypes = course?.map((item) => ({
                course: parseInt(item?.course),
                semester: parseInt(item?.semester),
                lecturesHours: parseInt(item?.lecture),
                independetWork: parseInt(item?.independetWork),
                labsHours: parseInt(item?.labsHours),
                practicesHours: item?.practicesHours,
                seminar: item?.seminar,
                intermediateAttestation: item?.intermediateCertification,
                contactWorkAudit: parseInt(item?.labsHours) + parseInt(item?.practicesHours) + parseInt(item?.lecture),
                colloquiumHours: '',
                otherHours: '',
                otherAuditHours: '',
                independentWork: parseInt(item?.independetWork),
                nonAuditHours: '',
                selfLearningTheory: '',
                selfLearningHomework: '',
                selfLearningCoursework: '',
                selfLearningOther: parseInt(item?.independetWork),
                contactWork: '',
                disciplineSize: '',
                intermediateAttestationForm: '',
                disciplineModuleVolume: '',

            }));
        }

        if (fileType === 'plx') {
            const course = xlsxParser.getCourses(disciplineCodeAndName);
            const arr = course?.map((c) => ({
                course: c,
                semesters: xlsxParser.getSemester(disciplineCodeAndName, c).map((s) => {
                    const contactWorkHours = xlsxParser.getContactWorkExHours(
                        disciplineCodeAndName,
                        c,
                        s
                    );
                    const contactWorkClassroom = xlsxParser
                        .getContactWork(disciplineCodeAndName, c, s)
                        .reduce((prev, curr) => (prev += parseInt(curr['_Количество'])), 0);
                    const independetWork = xlsxParser
                        .getIndependentWork(disciplineCodeAndName, c, s)
                        .reduce((prev, curr) => (prev += parseInt(curr['_Количество'])), 0);
                    const intermediateCertification = xlsxParser
                        .getIntermediateCertification(disciplineCodeAndName, c, s)
                        .reduce((prev, curr) => (prev += parseInt(curr['_Количество'])), 0);

                    return {
                        course: c,
                        semester: s,
                        contactWorkHours,
                        lecture: xlsxParser.getLectures(disciplineCodeAndName, c, s),
                        seminar: xlsxParser.getSeminar(disciplineCodeAndName, c, s),
                        contactWorkClassroom,
                        independetWork,
                        intermediateCertification,
                        totalVolumeOfTheDisciplineModule:
              contactWorkHours + independetWork + intermediateCertification,
                    };
                }),
            }));
            sizeByTypes = expandArr(arr, ['semesters']).map(
                ({
                    course,
                    semester,
                    lecture,
                    seminar,
                    independetWork,
                    intermediateCertification,
                    contactWorkHours,
                    contactWorkClassroom,
                }) => ({
                    course: parseInt(course),
                    semester: parseInt(semester),
                    contactWorkAudit: contactWorkClassroom,
                    lecturesHours: lecture,
                    seminarTypeStudy: seminar,
                    practicesHours: parseInt(seminar),
                    colloquiumHours: '',
                    labsHours: '',
                    otherHours: '',
                    otherAuditHours: contactWorkClassroom - (parseInt(lecture) + parseInt(seminar)),
                    independentWork: independetWork,
                    nonAuditHours: contactWorkHours,
                    selfLearningTheory: '',
                    selfLearningHomework: '',
                    selfLearningCoursework: '',
                    selfLearningOther: '',
                    intermediateAttestation: intermediateCertification,
                    contactWork: '',
                    disciplineSize: '',
                    intermediateAttestationForm: '',
                    disciplineModuleVolume: '',
                })
            );

            semesters = sizeByTypes.map((a) => parseInt(a?.semester));
        }

        if (!sizeByTypes.length) {
            sizeByTypes = emptyObj.sizeByTypes;
        }

        dispatch(
            setCurrentDocDetails({
                ...currentDoc,
                5.5: {
                    ...moduleDetails[5.5],
                    sizeByTypes,
                    semesters,
                },
            })
        );
    }, [tabDetails?.disciplineCodeAndName, dispatch]);

    const onNextBtnClick = () => {
        if (currentRpdIndex > -1) {
            const newRpdList = JSON.parse(JSON.stringify(rpdList));
            newRpdList[currentRpdIndex] = {
                ...newRpdList[currentRpdIndex],
                ..._pick(moduleDetails, Object.keys(moduleDetails)),
            };
            dispatch(setRpdList(newRpdList));
        }
        onNextClick(form);
    };

    useEffect(() => {
        const newObj = moduleDetails?.[tabKey]
            ? { ...moduleDetails?.[tabKey] }
            : { ...emptyObj };
        form.setFieldsValue(newObj);
    }, [rpdList?.length, currentRpdIndex]);
    return (
        <div className={b()}>
            <div className="constructor-form">
                <h4 className="title">{t('title-information')}</h4>
                <h4 className="program-title-name">
                    {currentRpdIndex === -1
                        ? ''
                        : `${t('rpd')}: ${
                            rpdList[currentRpdIndex]?.[5.1]?.disciplineCodeAndName
                        }`}
                </h4>
                <Form
                    form={form}
                    initialValues={initialValues}
                    onValuesChange={onValuesChange}
                    layout="vertical"
                >
                    <section>
                        <Form.Item name="developmentType" label="Вы разрабатываете:">
                            <Radio.Group>
                                <Radio value="дисциплины">Рабочую программу дисциплины</Radio>
                                <Radio value="дисциплины (модуля)">
                                    Рабочую программу дисциплины (модуля)
                                </Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            {...stringRules}
                            name="disciplineCodeAndName"
                            label="Код и наименование дисциплины (модуля) по учебному плану"
                        >
                            {xlsxLoaded && codeAndNameDisciplineModule.length && codeAndNameDisciplineModule.length ? (
                                <Select
                                    options={codeAndNameDisciplineModule}
                                    className="form-select"
                                    size="large"
                                    showSearch
                                    notFoundContent={t('not-found-content-text')}
                                    placeholder="Введите код и наименование"
                                />
                            ) : (
                                <InputV2 placeholder="Введите код и наименование" />
                            )}
                        </Form.Item>
                    </section>

                    <Divider />

                    <section>
                        <h5 className="subtitle subtitle-with-desc">
                            Разработчики рабочей программы дисциплины
                        </h5>
                        <p className="section-desc">Укажите авторов документа</p>
                        <Form.List name="author">
                            {(fields, { add, remove }) => (
                                <>
                                    <div className="form-items-list">
                                        {fields.map(({ key, name, ...restField }) => (
                                            <div className="form-items-group" key={key}>
                                                <Row
                                                    gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                                    align="bottom"
                                                    wrap={false}
                                                >
                                                    <Col flex="1">
                                                        <Row gutter={[{ sm: 16, md: 24, lg: 32 }, 16]} wrap>
                                                            <Col flex="1 1 393px">
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'position']}
                                                                    label={t('position')}
                                                                >
                                                                    <InputV2 placeholder={t('enter-position')} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col flex="1 1 468px">
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'fullName']}
                                                                    label="ФИО"
                                                                >
                                                                    <InputV2 placeholder={t('enter-full-name')} />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col>
                                                        <Form.Item>
                                                            <DeleteBtn onClick={() => remove(name)} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))}
                                    </div>
                                    <Form.Item>
                                        <ButtonV2
                                            type="link"
                                            onClick={() => add(emptyObj.author[0])}
                                            icon={<PlusOutlined />}
                                        >
                                            Добавить еще разработчика
                                        </ButtonV2>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </section>

                    <Divider />

                    <section>
                        <h5 className="subtitle">Руководитель рабочей группы</h5>
                        <Row gutter={32}>
                            <Col span={11}>
                                <Form.Item name="headPosition" label="Должность">
                                    <InputV2 placeholder={t('enter-position')} />
                                </Form.Item>
                            </Col>
                            <Col span={13}>
                                <Form.Item name="headFullName" label="ФИО">
                                    <InputV2 placeholder={t('enter-full-name')} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </section>

                    <Divider />
                    {rpdList?.length <= 100 ? (
                        <Next
                            onPreview={() => previewDoc(
                                tabKey,
                                {
                                    ...emptyObj,
                                    ...tabDetails,
                                    ...importDocDetails(importedTabDetails, tabKey),
                                },
                                dispatch
                            )}
                            onNextClick={() => onNextBtnClick(form)}
                        />
                    ) : null}
                </Form>
            </div>
        </div>
    );
};

export default WorkTitleTab;
