import React, { ReactNode } from 'react';
import fxml from 'fast-xml-parser';
import expandArr from 'utils/expandArr';
import he from 'he';
import isArray from 'lodash/isArray';
import { emptyDocDetails } from '../../../components/opop-constructor/components/dev-educational-program/constants';


const eduLevelLabels: Record<string, string> = {
  Специалитет: 'Специалитета',
  Бакалавриат: 'Бакалавриата',
  Магистратура: 'Магистратуры',
};

const removeDublicatesByKey = (arr: any[], key: string) => arr.filter((v, i, a) => a.findIndex((v2) => v2[key] === v[key]) === i);

export const PlxParser = () => {
  // plx data
  let loopPlans: Array<any> = [];
  let linePlans: Array<any> = [];
  let formEducation: Array<any> = [];
  let planCompetence: Array<any> = [];
  let compentences: Array<any> = [];
  let directoryTypeOfPractice: Array<any> = [];
  let directoryKindOfWork: Array<any> = [];
  let directoryTypeOfWork: Array<any> = [];
  let planCompDicipline: Array<any> = [];
  let plansNewHours: Array<any> = [];
  let titlePageData: any = null;

  const loadParsingData = (parsingData: string, details: any = {}) => {
    reset();
    try {
      const xmlData = fxml.parse(parsingData, {
        ignoreAttributes: false,
        attributeNamePrefix: '_',
      });
      const plxData = xmlData['Документ']['diffgr:diffgram'].dsMMISDB;

      if (!plxData) {
        return;
      }

      loopPlans = plxData['ПланыЦиклы'] || [];
      linePlans = plxData['ПланыСтроки'] || [];
      formEducation = plxData['ФормаОбучения'] || [];
      planCompetence = plxData['ПланыКомпетенции'] || [];
      compentences = plxData['Компетенции'] || [];
      directoryTypeOfPractice = plxData['СправочникВидыПрактик'] || [];
      planCompDicipline = plxData['ПланыКомпетенцииДисциплины'] || [];
      plansNewHours =  plxData['ПланыНовыеЧасы'] || [];
      directoryKindOfWork = plxData['СправочникВидыРабот'] || [];
      directoryTypeOfWork = plxData['СправочникТипаРабот'] || [];
      
      const founder = plxData['ПараметрыПлана']?.['_RUPMinistry'] || '';
      const oop = plxData['ООП'];
      const faculty = plxData['Факультеты'];
      const branches = plxData['Филиалы'];
      const eduLevel = plxData['Уровень_образования']?.find(
        (item: any) => item['_Код_записи'] == oop['_УровеньОбразования']
      );
      const formOfEdu = formEducation?.find(
        (item: any) => item['_Код'] == plxData['Планы']['_КодФормыОбучения']
      );
      const direction = oop?.['ООП']?.['_Название'] || '';
      const duration = {
        durationInYears: parseInt(oop['_СрокЛет']) || 0,
        durationInMonths: parseInt(plxData['Планы']['_СрокОбученияМесяцев']) || 0,
      };
      
      const getNameAndPos = () => {
        const list = plxData['ДолжЛица_Планы']
          .filter((x: any) => x['_Утвердил'] == 'true' && x['_КодПлана'] == plxData['Планы']['_Код'])
          .map((item: any) => ({
              ...item,
              _ДолжЛицо: plxData['ДолжностныеЛица'].find(
                (x: any) => x['_Код'] === item['_КодДолжЛица']
              ),
          }));

        return list?.length > 0
          ? {
            approvedPosition: list[0]['_ДолжЛицо']['_Должность'],
            approvedFullName: list[0]['_ДолжЛицо']['_ФИО'],
          }
          : { approvedFullName: '', approvedPosition: '' };
      };
      const { approvedFullName, approvedPosition } = getNameAndPos();

      const getFullAndShortNameOfUniversity = () => {
        if (faculty) {
          const filialCode = isArray(faculty) ? faculty[0]['_Код_филиала'] : faculty['_Код_филиала'];

          if (isArray(branches)) {
              return branches.find((x) => x['_Код'] == filialCode)?.['_Полное_название'] || '';
          }
          return branches?.['_Полное_название'] || '';
        }
        return !isArray(branches) ? branches?.['_Полное_название'] : '';
      };

      const getBy = () => {
        const code = oop['_Шифр'].split('.')[1] || '';
        switch (code) {
          case '03':
            return 'Направлению подготовки';
          case '04':
            return 'Направлению подготовки';
          case '05':
            return 'Специальности';
          default:
            return '';
        }
      };

      titlePageData = {
        founder,
        direction,
        fullAndShortNameOfUniversity: he.decode(
          getFullAndShortNameOfUniversity()
        ),
        approvedPosition,
        approvedFullName,
        by: getBy(),
        program: eduLevelLabels?.[eduLevel?.['_ВидПлана']] || '',
        directionCodeOrName: `${oop['_Шифр']} ${oop['_Название']}`,
        qualification: eduLevel?.['_Уровень']?.slice(0, -1) || '',
        studyForm: formOfEdu?.['_ФормаОбучения'] || '',
        accessYear: parseInt(
          plxData['Планы']['_ГодНачалаПодготовки']
        ),
      }

      const obj = {
        1.1: {
          ...emptyDocDetails[1.1],
          ...details?.[1.1],
          ...titlePageData,
        },
        1.2: {
          ...emptyDocDetails[1.2],
          ...details?.[1.2],
          duration,
        },
        plxData: {
          fileType: 'plx',
          loopPlans,
          linePlans,
          formEducation,
          planCompetence,
          compentences,
          directoryTypeOfPractice,
          planCompDicipline,
          plansNewHours,
          directoryKindOfWork,
          directoryTypeOfWork,
          titlePageData: { ...titlePageData, duration },
        }
      };
      titlePageData = { ...titlePageData, duration };
      return obj;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const setPagesData = (data: any = {}) => {
    loopPlans = data.loopPlans || [];
    linePlans = data.linePlans || [];
    formEducation = data.formEducation || [];
    planCompetence = data.planCompetence || [];
    compentences = data.compentences || [];
    directoryTypeOfPractice = data.directoryTypeOfPractice || [];
    directoryKindOfWork = data.directoryKindOfWork || [];
    directoryTypeOfWork = data.directoryTypeOfWork || [];
    planCompDicipline = data.planCompDicipline || [];
    plansNewHours = data.plansNewHours || [];
    titlePageData = data.titlePageData || null;
  };

  const reset = () => {
    loopPlans = [];
    linePlans = [];
    formEducation = [];
    planCompetence = [];
    compentences = [];
    directoryTypeOfPractice = [];
    directoryKindOfWork = [];
    directoryTypeOfWork = [];
    planCompDicipline = [];
    plansNewHours = [];
  };

  const clearForm = (docDetails: any) => {
    if (!titlePageData) return docDetails;
    const {
      '1.1': titleInfo = {},
      '1.2': generalInfo = {},
      '1.4': resultEduPrograms = {},
      '5.1': titleInfoRpd = {},
      '5.3': planningResultDiscipline = {},
      '5.5': volumeDiscipline = {},
      '6.1': titleInfoRpp = {},
      '6.2': generalCharacteristic = {},
      '6.3': planningLearnEdu = {},
      '6.6': practicMarks = {},
    } = docDetails;

    const {
      founder,
      direction,
      fullAndShortNameOfUniversity,
      approvedPosition,
      approvedFullName,
      by,
      program,
      directionCodeOrName,
      qualification,
      studyForm,
      accessYear,
      duration,
    } = titlePageData;

    const { durationInYears, durationInMonths } = generalInfo.duration;

    // 1.2
    if (
      duration.durationInYears === durationInYears
      && duration.durationInMonths === durationInMonths
    ) {
      generalInfo.duration.durationInYears = 0;
      generalInfo.duration.durationInMonths = 0;
    }

    // 5.1, 5.5
    let newVolumeDiscipline = { ...volumeDiscipline };
    const newTitleInfoRpd = { ...titleInfoRpd };
    const disciplineModuleList = getCodeAndNameDisciplineModule();
    const hasDiscipline = disciplineModuleList.some((item: any) => item.value === titleInfoRpd.disciplineCodeAndName);

    if (hasDiscipline) {
      newTitleInfoRpd.disciplineCodeAndName = '';
      planningResultDiscipline.results = planningResultDiscipline.results.map((item: any) => {
        const list = item.list.map((codeIdk: any) => ({ ...codeIdk, codeAndNameIndicator: '' }));
        return { list, codeAndName: '' };
      });
      newVolumeDiscipline = { ...emptyDocDetails[5.5] };
    }

    // 6.1, 6.2, 6.3
    let newGeneralCharacteristic = { ...generalCharacteristic };
    const newTitleInfoRpp = { ...titleInfoRpp };
    const typeOfPracticeList = getTypeOfPractice();
    const hasPractice = typeOfPracticeList.some((item: any) => item.practiceType === titleInfoRpp.type);

    if (hasPractice) {
      newTitleInfoRpp.type = '';
      planningLearnEdu.tasks = planningLearnEdu.tasks.map((item: any) => {
        const endToEndDigitlTech = item.endToEndDigitlTech.map((tech: any) => ({ ...tech, idkCode: '' }));
        return {
          pickCode: '',
          endToEndDigitlTech,
        };
      });
      practicMarks.criteria = practicMarks.criteria.map((item: any) => ({ ...item, codeName: '' }));
      newGeneralCharacteristic = { ...emptyDocDetails[6.2] };
    }

    return {
      ...docDetails,
      1.1: {
        ...titleInfo,
        founder: isEqualNumber(founder, titleInfo.founder),
        direction: isEqualNumber(direction, titleInfo.direction),
        fullAndShortNameOfUniversity: isEqualNumber(fullAndShortNameOfUniversity, titleInfo.fullAndShortNameOfUniversity),
        approvedPosition: isEqualNumber(approvedPosition, titleInfo.approvedPosition),
        approvedFullName: isEqualNumber(approvedFullName, titleInfo.approvedFullName),
        by: isEqualNumber(by, titleInfo.by),
        program: isEqualNumber(program, titleInfo.program),
        directionCodeOrName: isEqualNumber(directionCodeOrName, titleInfo.directionCodeOrName),
        qualification: isEqualNumber(qualification, titleInfo.qualification),
        studyForm: isEqualNumber(studyForm, titleInfo.studyForm),
        accessYear: isEqualNumber(accessYear, titleInfo.accessYear),
      },
      1.2: { ...generalInfo },
      1.4: {
        universalComp: isEqualArray(getNamesCategoryUK(), resultEduPrograms.universalComp, 'universalComp'),
        profComp: isEqualArray(getNamesCategoryPK(), resultEduPrograms.profComp, 'profComp'),
        commonComp: isEqualArray(getNamesCategoryOPK(), resultEduPrograms.commonComp, 'commonComp'),
      },
      5.1: newTitleInfoRpd,
      5.5: newVolumeDiscipline,
      6.1: newTitleInfoRpp,
      6.2: newGeneralCharacteristic,
      6.3: planningLearnEdu,
      6.6: practicMarks,
    };
  };

  const isEqualNumber = (a: any, b: any): any => {
    if (a === b) {
        return '';
    }
    return b;
  };

  const isEqualArray = (options:any, details:any, arrName:string) => {
    const set = new Set();
    for (const { label } of options) set.add(label);
    const intersection = details.filter(({ name, namePc }: any) => !set.has(name || namePc));
    if(intersection.length){
      return intersection
    }
    return [emptyDocDetails[1.4]?.[arrName]];
  };

  // 5.1
  const getPlxCodeAndNameOfDisciplines = () => removeDublicatesByKey(
    linePlans.map((item: any) => ({
        code: item['_Код'],
        discipline: item['_Дисциплина'],
    })),
    'discipline'
  );

  const getCodeAndNameDisciplineModule = () => {
    return getPlxCodeAndNameOfDisciplines().map((item: any) => ({
      label: item.discipline,
      value: item.discipline,
    }));
  }

  // 6.1
  const getTypeOfPractice = () => {
    return directoryTypeOfPractice.map((item: any) => ({
      code: item['_Код'],
      practiceType: item['_Наименование'],
    })) || [];
  }

  // 6.2
  const getTypeOfPracticeRpp = () => {
    const type = linePlans.filter((item: any) => item['_ТипОбъекта'] == 3);
    return type.map((item: any) => ({
      practiceTask: item['_Дисциплина'].split(' ')[1],
    }));
  };

  const getRppCourse = (practiceCode: string) => {
    const filerteredPlanLines = linePlans
      .filter(
        (item: any) => item['_ТипОбъекта'] == 3 && item?.['_ВидПрактики'] === practiceCode
      )
      .map((item: any) => item['_Код']);

    const codesPlansNewHours = plansNewHours.filter(
      (item: any) => filerteredPlanLines.includes(item['_КодОбъекта'])
      && item['_КодВидаРаботы'] == 103
      && item['_КодТипаЧасов'] != 1
    );

    return codesPlansNewHours.map((item: any) => ({
      course: +item['_Курс'],
      semester: item['_Семестр'],
    }));
  };

  const getDurationInWeeks = (practiceCode: string) => {
    const duartionWeek = linePlans.find(
      (item: any) => item['_ТипОбъекта'] == 3 && item?.['_ВидПрактики'] === practiceCode
    );
    return (duartionWeek?.['_ЧасовПоЗЕТ'] || 0) / 54;
  };

  const getGeneralCreditUnits = (practiceCode: string) => {
    const creditUnits = linePlans.find(
      (item: any) => item['_ТипОбъекта'] == 3 && item?.['_ВидПрактики'] === practiceCode
    );
    return creditUnits ? creditUnits['_ЗЕТфакт'] : null;
  };

  const getTotalScopeInHours = (practiceCode: string) => {
    const totalHours = linePlans.find(
      (item: any) => item['_ТипОбъекта'] == 3 && item?.['_ВидПрактики'] === practiceCode
    );
    return totalHours ? totalHours['_ЧасовПоЗЕТ'] : null;
  };

  const getContactWorkExRPP = (practiceCode: string) => {
    const filteredPlanLines = linePlans
      .filter(
        (item: any) => item['_ТипОбъекта'] == 3 && item?.['_ВидПрактики'] === practiceCode
      )
      .map((item: any) => item['_Код']);

    const filteredDirectoryWorks = directoryKindOfWork
      .filter((item: any) => item['_Контактный'] == 'true')
      .map((item: any) => item['_Код']);

    const contactWork = plansNewHours.filter(
      (item: any) => filteredPlanLines.includes(item['_КодОбъекта'])
    && filteredDirectoryWorks.includes(item['_КодВидаРаботы'])
    );

    const courseAndSemester = getRppCourse(practiceCode);

    const completedForm = courseAndSemester.map((item: any) => contactWork.reduce((prev: number, current: any) => {
      if (
        current['_Курс'] == item.course
        && current['_Семестр'] == item.semester
      ) {
        return prev + parseInt(current['_Количество']);
      }
      return prev;
    }, 0));

    return completedForm;
  };

  const getIntermediateCertificationRPP = (practiceCode: string) => {
    const filteredPlanLines = linePlans
      .filter(
        (item: any) => item['_ТипОбъекта'] == 3 && item?.['_ВидПрактики'] === practiceCode
      )
      .map((item: any) => item['_Код']);

    const planHours = plansNewHours
      .filter((item: any) => filteredPlanLines.includes(item['_КодОбъекта']))
      .map((item: any) => item['_КодВидаРаботы']);

    const intermediateAttestation = directoryKindOfWork.find(
      (item: any) => planHours.includes(item['_Код']) && item['_КодТипРабот'] == 7
    );

    return intermediateAttestation
      ? intermediateAttestation['_Название']
      : null;
  };

  // 6.3
  const getCompetencyAndNameRpp = (practiceType: string) => {
    const filteredPlanLines = linePlans
      .filter(
        (item: any) => item['_ТипОбъекта'] == 3 && item?.['_ВидПрактики'] === practiceType
      )
      .map((item: any) => item['_Код']);

    const codesPlanCompDicipline = planCompDicipline
      .filter((item: any) => filteredPlanLines.includes(item['_КодСтроки']))
      .map((item: any) => item['_КодКомпетенции']);

    const codesPlanCompetence = expandArr(planCompetence, [
      'ПланыКомпетенции',
    ]).filter((item: any) => codesPlanCompDicipline.includes(item['_Код']));

    return (
      codesPlanCompetence.map((item: any) => `${item['_ШифрКомпетенции']}. ${item['_Наименование']}`) || []
    );
  };

  //5.X

  // 5.3
  const getCompetencyAndName = (disciplineName: string) => {
    const planCompetenceExpanded = expandArr(planCompetence, [
      'ПланыКомпетенции',
    ]);

    const codes = linePlans
      .filter((item: any) => item['_Дисциплина'] == disciplineName)
      .map((item: any) => item['_Код']);

    const codesCompetence = planCompDicipline
      .filter((item: any) => codes.includes(item['_КодСтроки']))
      .map((item: any) => item['_КодКомпетенции']);

    const codesParent = planCompetenceExpanded
      .filter((item: any) => codesCompetence.includes(item['_Код']))
      .map((item: any) => item['_КодРодителя']);

    const cipherAndNameCompetences = planCompetence
      .filter((item: any) => codesParent.includes(item['_Код']))
      .map((item: any) => `${item['_ШифрКомпетенции']} ${item['_Наименование']}`);
    
    return cipherAndNameCompetences;
  };

  const getCompetencyAndName6 = (data: string, codeName: string) => {
    const planComp = planCompetence.filter((item: any) => {
      const codeAndName = `${item['_ШифрКомпетенции']} ${item['_Наименование']}`;
      if (codeAndName == codeName) {
          return true;
      }
      return false;
  });
  return expandArr(planComp, [
      'ПланыКомпетенции',
  ]).map((item: any) => ({
      label: `${item['_ШифрКомпетенции']} ${item['_Наименование']}`,
      value: `${item['_ШифрКомпетенции']} ${item['_Наименование']}`,
  }));
  };

  // 5.5
  const getCourses = (codeAndName: string) => {
    const lineCodes = linePlans.map((item: any) => ({
      ...item,
      planNewHours:plansNewHours.filter(
        (x: any) => x['_КодОбъекта'] === item['_Код']
      ),
    }));

    const line = lineCodes.find((x: any) => x['_Дисциплина'] == codeAndName);

    if (!line) {
      return null;
    }

    return (
      [
        ...new Set(line.planNewHours.map((x: string) => x?.['_Курс'])),
      ] || null
    );
  };

  const getSemester = (codeAndName: string, course: string) => {
    const lineCodes = linePlans.map((item: any) => ({
      ...item,
      planNewHours: plansNewHours.filter(
          (x: any) => x['_КодОбъекта'] === item['_Код']
      ),
    }));
    const line = lineCodes.find((x: any) => x['_Дисциплина'] == codeAndName);

    if (!line) {
      return null;
    }
    return [
      ...new Set(line.planNewHours?.filter((x: any) => x['_Курс'] == course).map((x:any) => x['_Семестр'])),
    ] || null;
  };

  const getContactWorkEx = (
    codeAndName: string,
    course: string,
    semester: string
  ) => plansNewHours
    .map((item: any) => ({
        ...item,
        codeLine:
      linePlans.find((x: any) => item['_КодОбъекта'] == x['_Код']) || null,
        workView:
      directoryKindOfWork.find(
          (x: any) => x['_Код'] == item['_КодВидаРаботы']
      ) || null,
    }))
    .filter(
        (item: any) => item['_Курс'] == course
      && item['_Семестр'] == semester
      && item.workView['_Контактный'] == 'true'
      && item.workView['_Аудиторный'] == 'true'
      && item.codeLine['_Дисциплина'] == codeAndName
    ) || [];

  const getContactWorkExHours = (
    codeAndName: string,
    course: string,
    semester: string
  ) => getContactWorkEx(codeAndName, course, semester).reduce((prev: any, curr: any) => prev += parseInt(curr['_Количество']), 0);

  const getContactWork = (
    codeAndName: string,
    course: string,
    semester: string
  ) => plansNewHours
    .map((item: any) => ({
        ...item,
        codeLine:
      linePlans.find((x: any) => item['_КодОбъекта'] == x['_Код']) || null,
        workView:
      directoryKindOfWork.find(
          (x: any) => x['_Код'] == item['_КодВидаРаботы']
      ) || null,
    }))
    .filter(
        (item: any) => item['_Курс'] == course
      && item['_Семестр'] == semester
      && item.workView['_Код'] != '108'
      && item.workView['_Код'] != '107'
      && item.codeLine['_Дисциплина'] == codeAndName
    ) || [];

  const getIndependentWork = (
    codeAndName: string,
    course: string,
    semester: string
  ) => plansNewHours
    .map((item: any) => ({
        ...item,
        codeLine:
      linePlans.find((x: any) => item['_КодОбъекта'] == x['_Код']) || null,
        workView:
      directoryKindOfWork.find(
          (x: any) => x['_Код'] == item['_КодВидаРаботы']
      ) || null,
    }))
    .filter(
        (item: any) => item['_Курс'] == course
      && item['_Семестр'] == semester
      && item.workView['_Код'] == '107'
      && item.codeLine['_Дисциплина'] == codeAndName
    ) || [];

  const getIntermediateCertification = (
    codeAndName: string,
    course: string,
    semester: string
  ) => plansNewHours
    .map((item: any) => ({
        ...item,
        codeLine:
      linePlans.find((x: any) => item['_КодОбъекта'] == x['_Код']) || null,
        workView:
      directoryKindOfWork.find(
          (x: any) => x['_Код'] == item['_КодВидаРаботы']
      ) || null,
    }))
    .filter(
        (item: any) => item['_Курс'] == course
      && item['_Семестр'] == semester
      && item.workView['_Код'] == '108'
      && item.codeLine['_Дисциплина'] == codeAndName
    ) || [];

  const getLectures = (codeAndName: string, course: string, semester: string) => plansNewHours
    .map((item: any) => ({
        ...item,
        codeLine:
      linePlans.find((x: any) => item['_КодОбъекта'] == x['_Код']) || null,
        workView:
      directoryKindOfWork.find(
          (x: any) => x['_Код'] == item['_КодВидаРаботы']
      ) || null,
    }))
    .filter(
        (item: any) => item['_Курс'] == course
      && item['_Семестр'] == semester
      && item.workView['_Код'] == '101'
      && item.codeLine['_Дисциплина'] == codeAndName
    ).reduce((prev: any, curr: any) => prev += parseInt(curr['_Количество']) || 0, 0);

  const getSeminar = (codeAndName: string, course: string, semester: string) => plansNewHours
    .map((item: any) => ({
        ...item,
        codeLine:
      linePlans.find((x: any) => item['_КодОбъекта'] == x['_Код']) || null,
        workView:
      directoryKindOfWork.find(
          (x: any) => x['_Код'] == item['_КодВидаРаботы']
      ) || null,
    }))
    .map((item: any) => ({
        ...item,
        workType:
      directoryTypeOfWork.find(
          (x: any) => x['_Код'] == item.workView['_КодТипРабот']
      ) || null,
    }))
    .filter(
        (item: any) => item['_Курс'] == course
      && item['_Семестр'] == semester
      && item?.workType?.['_Название']?.includes('семинарский')
      && item.codeLine['_Дисциплина'] == codeAndName
    ).reduce((prev: any, curr: any) => prev += parseInt(curr['_Количество']) || 0, 0);

  // 1.4
  const getPlxNamesCategory = (name: string) => {
    const competenceNames = compentences
      .filter((item: any) => item['_ШифрКомпетенции'].includes(name))
      .map((item: any) => item);

    const cipherComp = competenceNames.map((item: any) => item['_ШифрКомпетенции']);

    const planCompetenceNames = planCompetence
      .filter((item: any) => item['_ШифрКомпетенции'].includes(name)
          && !cipherComp.includes(item['_ШифрКомпетенции']))
      .map((item: any) => item);
    
    return [...competenceNames, ...planCompetenceNames].map((item: any) => ({
      label: item['_ШифрКомпетенции'],
      value: item['_ШифрКомпетенции'],
      name: item['_Наименование'],
    }));
  };

  const getNamesCategoryUK = () => getPlxNamesCategory('УК');

  const getNamesCategoryOPK = () => getPlxNamesCategory('ОПК');

  const getNamesCategoryPK = () => {  
    const competenceNames = compentences
      .filter((item: any) => item['_ШифрКомпетенции'].split('-')[0] == 'ПК')
      .map((item: any) => item);

    const cipherComp = competenceNames.map((item: any) => item['_ШифрКомпетенции']);

    const planCompetenceNames = planCompetence
        .filter((item: any) => item['_ШифрКомпетенции'].split('-')[0] == 'ПК'
            && !cipherComp.includes(item['_ШифрКомпетенции']))
        .map((item: any) => item);
    
    return [...competenceNames, ...planCompetenceNames].map((item: any) => ({
      label: `${item['_ШифрКомпетенции']} ${item['_Наименование']}`,
      value: `${item['_ШифрКомпетенции']} ${item['_Наименование']}`,
      code: item['_ШифрКомпетенции'],
    }));
  };

  const getCodeAndNameOfIndicator = (code: string, index: number, parentIndex: number) => {
    const planComp = planCompetence.filter((item: any) => item['_ШифрКомпетенции'] == code);
    const planCompExpanded = expandArr(planComp, [
      'ПланыКомпетенции',
    ]);
    const list = planCompExpanded.map((item: any) => ({
        indicatorCode: item['_ШифрКомпетенции'],
        indicatorName: item['_Наименование'],
    }));
    return list;
  };

  const isLoaded = () => {
    return compentences.length && planCompetence.length;
  };

  return {
      loadParsingData,
      reset,
      isLoaded,
      getNamesCategoryUK,
      getNamesCategoryOPK,
      getNamesCategoryPK,
      getCodeAndNameDisciplineModule,
      getTypeOfPractice,
      getTypeOfPracticeRpp,
      getCompetencyAndName,
      getCompetencyAndNameRpp,
      getCompetencyAndName6,
      getCodeAndNameOfIndicator,
      setPagesData,
      getCourses,
      getRppCourse,
      getDurationInWeeks,
      getGeneralCreditUnits,
      getTotalScopeInHours,
      getContactWorkExRPP,
      getIntermediateCertificationRPP,
      getSemester,
      getContactWorkEx,
      getContactWorkExHours,
      getIndependentWork,
      getIntermediateCertification,
      getContactWork,
      getLectures,
      getSeminar,
      clearForm,
  };
};