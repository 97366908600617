import {
    call, put, takeLatest
} from 'redux-saga/effects';
import { getUnioneToken } from '@unione-pro/unione.commons.webpack';
import { serverErrorsLogger } from '../../utils/server-errors-logger';
import {
    getOpopById, setAccessId, setSearchedUser, setUserRole
} from './constants';
import {
    getOpopByIdApi, getSearchedUser, setAccess, setRole
} from './api';


function* getSearchedUserSaga({ payload }) {
    const { success, failure } = setSearchedUser;
    const token = yield call(getUnioneToken);
    try {
        const { data } = yield call(getSearchedUser, payload, token);
        yield put({ type: success, payload: data });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}

function* setUserRoleSaga({ id, role }) {
    const { success, failure } = setUserRole;
    try {
    const token = yield call(getUnioneToken);

        const { data } = yield call(setRole, id, role, token);
        yield put({ type: success, payload: data });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}

function* setAccessIdSaga({ id, accessId }) {
    const { success, failure } = setAccessId;
    try {
    const token = yield call(getUnioneToken);
        const { data } = yield call(setAccess, id, accessId, token);
        yield put({ type: success, payload: data });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}

function* getOpopByIdSaga({ id }) {
    const { success, failure } = getOpopById;
    try {
    const token = yield call(getUnioneToken);
        const { data } = yield call(getOpopByIdApi, id, token);
        yield put({ type: success, payload: data });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}

export default function* rootSaga() {
    yield takeLatest(setSearchedUser.tries, getSearchedUserSaga);
    yield takeLatest(setUserRole.tries, setUserRoleSaga);
    yield takeLatest(setAccessId.tries, setAccessIdSaga);
    yield takeLatest(getOpopById.tries, getOpopByIdSaga);
}
