import React, { useMemo } from 'react';
import bem from 'easy-bem';
import {
    Form, Divider, Upload, message
} from 'antd';
import ButtonV2 from 'components/button-v2';
import { ReactComponent as AttachIcon } from 'assets/icons/attach.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as FilesIcon } from 'assets/icons/files.svg';
import last from 'lodash/last';
import { useHistory } from 'react-router-dom';
import { useXlsxParser } from 'utils/excel-parse';
import { uploadFile } from 'utils/upload';
import {
    removeAcademicPlan,
    setCurrentDocDetails,
    updateDocById
} from '../../../../../../models/documents/actions';

import './style.less';


const tabKey = '3.0';

const readFileAsText = (file) => new Promise((resolve) => {
    try {
        const reader = new FileReader();
        reader.onload = (event) => resolve(event.target.result);
        reader.readAsText(file);
    } catch {
        return null;
    }
});

const accept = '.plx, .xls, xlsx';

const AcademicPlanTab = ({ onNextClick }) => {
    const b = bem('calendar-schedule-tab');
    const dispatch = useDispatch();
    const history = useHistory();
    const docDetails = useSelector((state) => state.documents.documentDetails);
    const academicPlans = useSelector((state) => state.documents.documentDetails?.[tabKey]?.academicPlans || []);
    const rpdList = useSelector((state) => state.documents.rpdList);
    const rppList = useSelector((state) => state.documents.rppList);
    const xlsxParser = useXlsxParser();

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const accept = useMemo(() => {
        if (academicPlans?.length === 0) {
            return '.xls, .xlsx, .plx';
        }
        const isPlx = academicPlans.some((item) => item.filename?.includes('.plx'));
        if (isPlx) {
            return '.xls, .xlsx';
        }
        return '.plx';
    }, [academicPlans]);

    const beforeUpload = (file) => {
        const extension = last(file.name.split('.'));
        if (!accept.split(', ').includes(`.${extension}`)) {
            message.error('Загрузить можно только один XLS или XLSX файл');
            return Upload.LIST_IGNORE;
        }
        return false;
    };

    const handleDelete = ({ id }) => {
        dispatch(removeAcademicPlan(id));
        const planList = academicPlans.filter((file) => file.id !== id);
        const details = xlsxParser.clearForm(docDetails);
        dispatch(setCurrentDocDetails({
            ...details,
            '3.0': { academicPlans: planList }
        }));
        if (details?.id) {
            dispatch(updateDocById(
                details?.id,
                { ...details, '3.0': { academicPlans: planList, parsingData: null } },
                rpdList,
                rppList
            ));
        };
        if (xlsxParser.isLoaded?.()) {
            xlsxParser.reset();
            history.push({ ...history.location, hash: '#1.1' });
        }
    };

    const handleChange = async (paylaod) => {
        const { file } = paylaod;
        const res = await uploadFile(file);
        const details = { ...docDetails };

        if (file.name.includes('.xls') || file.name.includes('.xlsx')) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const result = e?.target?.result || '';
                const data = await xlsxParser.loadParsingData(result, details, 'excel');
                if (data && details.id) {
                    details[1.1] = { ...(docDetails?.[1.1] || {}), ...data[1.1] };
                    details[1.5] = data[1.5];
                    details['3.0'] = { academicPlans: [...academicPlans, res.data[0]], parsingData: data?.xlsxData || [] };
                    dispatch(
                        setCurrentDocDetails({ ...details })
                    );
                    dispatch(updateDocById(
                        details?.id,
                        details,
                        rpdList,
                        rppList
                    ));
                }
            };
            reader.readAsBinaryString(file);
        } else if (file.name.includes('.plx')) {
            const text = await readFileAsText(file);
            if (!text) return;
            const data = await xlsxParser.loadParsingData(text, details, 'plx');
            if (data && details.id) {
                details[1.1] = data[1.1];
                details[1.2] = data[1.2];
                details['3.0'] = { academicPlans: [...academicPlans, res.data[0]], parsingData: data?.plxData };
                dispatch(
                    setCurrentDocDetails({ ...details })
                );
                dispatch(updateDocById(
                    details?.id,
                    details,
                    rpdList,
                    rppList
                ));
            }
        } else if (file.name.includes('.plx')) {
            const text = await readFileAsText(file);
            if (!text) return;
            const data = await xlsxParser.loadParsingData(text, details, 'plx');
            if (data && details.id) {
                details[1.1] = data[1.1];
                details[1.2] = data[1.2];
                details['3.0'] = { academicPlans: [...academicPlans, res.data[0]], parsingData: data?.plxData };
                dispatch(
                    setCurrentDocDetails({ ...details })
                );
                dispatch(updateDocById(
                    details?.id,
                    details,
                    rpdList,
                    rppList
                ));
            }
        } else {
            dispatch(
                setCurrentDocDetails(details)
            );
        }
    };

    return (
        <div className={b()}>
            <div className="constructor-form">
                <h4 className="title">Учебный план</h4>
                <p className="item-label">
                    Если вам необходимо заменить файл, удалите его и прикрепите новый. 
                    Файл должен быть следующих форматов: .plx, .xlsx, .xls.
                </p>
                <p className="item-label">
                    Внимание! При замене файла учебного плана, автозаполенные ранее поля будут переписаны.
                </p>
                <div className="uploaded-list">
                    {academicPlans?.map((file, index) => (
                        <div className="list-item" key={index}>
                            <div className="file-name">
                                <FilesIcon className="file-icon" />
                                <span>{file.filename}</span>
                            </div>
                            <DeleteIcon
                                className="delete-icon"
                                onClick={() => handleDelete(file)}
                            />
                        </div>
                    ))}
                </div>
                <Form.Item
                    name="upload"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                >
                    <Upload
                        className={b('upload-item')}
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                        showUploadList={false}
                        accept={accept}
                        name="academic-plan"
                        maxCount={1}
                        disabled={academicPlans?.length >= 1}
                    >
                        <p>
                            {
                                academicPlans?.length === 1 ? null
                                    : (
                                        <ButtonV2
                                            className={b('upload-button')}
                                            type="link"
                                            icon={<AttachIcon />}
                                        >
                                            Прикрепить учебный план
                                        </ButtonV2>
                                    )
                            }
                        </p>
                    </Upload>
                </Form.Item>
                <Divider />

                <Form.Item>
                    <ButtonV2
                        className="form-next-btn"
                        onClick={() => onNextClick()}
                        type="primary"
                    >
                        Сохранить. Перейти далее
                    </ButtonV2>
                </Form.Item>
            </div>
        </div>
    );
};

export default AcademicPlanTab;
