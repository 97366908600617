import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Col, Divider, Form, Row
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import bem from 'easy-bem';
import { useDispatch, useSelector } from 'react-redux';
import useDebouncedCallback from 'utils/use-debounce-callback';
import expandArr from 'utils/expandArr';
import Select from 'components/select';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import { Link } from 'react-router-dom';
import DeleteBtn from 'components/delete-btn';
import { updateTabDetails } from 'utils/dispatches';
import Next from '../../../../../next';
import { ButtonV2, InputV2 } from '../../../../../index';
import OOCTextEditor from '../../../../../text-editor';
import { previewDoc } from '../../utils';
import { emptyDocDetails } from '../../constants';
import { useXlsxParser } from 'utils/excel-parse';


const tabKey = '6.6';
const emptyObj = emptyDocDetails[tabKey];


const WorkProgramEvaluation = ({ onNextClick }) => {
    const { t } = useTranslation('work-evaluation-results');
    const b = bem('work-evaluation-results');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);
    const { workProgram = '', type = '' } = useSelector((state) => state.documents.documentDetails?.['6.1'] || {});

    const xlsxParser = useXlsxParser();
    const plxLoaded = xlsxParser.isLoaded();

    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const rppList = useSelector((state) => state.documents.rppList);
    const currentRppIndex = useSelector((state) => state.documents.currentRppIndex);
    const emptyCriteria = {
        codeName: '',
        plannedResultsOwn: '',
        plannedResultsAble: '',
        plannedResults: '',
    };

    const { tasks = [] } = useSelector((state) => state.documents.documentDetails?.['6.3'] || {});
    const codeAndNameCompList = useMemo(
        () => (tasks || [])
            .filter(({ pickCode }) => pickCode)
            .map(({ pickCode }) => ({
                value: pickCode,
                label: pickCode,
            })),
        [tasks]
    );
    const toKnowTeachingResultsList = useMemo(
        () => expandArr(tasks || [], [
            'endToEndDigitlTech'
        ])
            .filter(({ toKnow }) => toKnow)
            .map(({ toKnow }) => ({
                label: toKnow,
                value: toKnow
            })),
        [tasks]
    );
    const toAbleTeachingResultsList = useMemo(
        () => expandArr(tasks || [], [
            'endToEndDigitlTech'
        ])
            .filter(({ toAble }) => toAble)
            .map(({ toAble }) => ({
                label: toAble,
                value: toAble
            })),
        [tasks]
    );
    const toOwnTeachingResultsList = useMemo(
        () => expandArr(tasks || [], [
            'endToEndDigitlTech'
        ])
            .filter(({ toOwn }) => toOwn)
            .map(({ toOwn }) => ({
                label: toOwn,
                value: toOwn
            })),
        [tasks]
    );

    const plxCompList = useMemo(() => {
        if (!plxLoaded || !type) {
            return [];
        }

        const practiceType = xlsxParser.getPracticeType?.()?.find(({ practiceType }) => practiceType === type);
        if (!practiceType) {
            return [];
        }

        const list = xlsxParser.getRppCompetenceCodeAndName(practiceType.code);
        return list;
    }, [type]);

    const onValuesChange = useDebouncedCallback((changed, all) => {
        const newTabDetails = {
            ...all,
        };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);
    useEffect(() => {
        updateTabDetails(tabKey, initialValues, dispatch);
        const newCriteria = tabDetails?.criteria?.map((item) => {
            let criteriaItem = { ...item };
            const isExistCode = tasks.some((elem) => elem.pickCode === criteriaItem?.codeName);
            if (!isExistCode) {
                criteriaItem = emptyCriteria;
            }
            return criteriaItem;
        });
        form.setFieldsValue({ criteria: newCriteria });
    }, []);
    useEffect(() => {
        const newTabDetails = { ...form.getFieldsValue() };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, [form]);
    useEffect(() => {
        const newObj = rppList[currentRppIndex]?.[tabKey] ? { ...tabDetails?.[tabKey] } : { ...emptyObj };
        form.setFieldsValue(newObj);
    }, [rppList?.length, currentRppIndex]);

    return (
        <div className="constructor-form">
            <h4 className="title m-24">{t('title')}</h4>
            <h4 className="program-title-name">
                {currentRppIndex === -1 ? '' : `${t('rpp')} ${workProgram} ${rppList[currentRppIndex][6.1]?.type}`}
            </h4>
            <Form
                form={form}
                initialValues={initialValues}
                onValuesChange={onValuesChange}
                layout="vertical"
            >
                <Form.Item
                    name="typeForm"
                    label={t('practice-results')}
                    className="m-20"
                >
                    <InputV2
                        placeholder={t('type-form')}
                    />
                </Form.Item>
                <Form.Item
                    name="requirements"
                    className="m-20"
                    label={t('requirements')}
                >
                    <OOCTextEditor
                        style={{ height: '620px' }}
                        placeholder={t('volume-requirements')}
                    />
                </Form.Item>
                <Divider />

                <h5 className="subtitle">{t('criteria')}</h5>
                <Form.List name="criteria">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({
                                key, name, ...restField
                            }) => (
                                <div className={b('item')} key={key}>
                                    <Row
                                        align="bottom"
                                        className="m-20"
                                    >
                                        <Col span={22}>
                                            <div className="item-block-group">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'codeName']}
                                                    label={t('code-name')}
                                                    {...handleFieldEnableStatus(
                                                        !!codeAndNameCompList?.length,
                                                        <span>
                                                            {`${t('fill-in-field-first-form')} 
                                                            "${t('code-name')}" 
                                                            ${t('in-chapter')} `}
                                                            <Link
                                                                to={(location) => ({ ...location, hash: '#6.3' })}
                                                            >
                                                                {t('planned-results-edu')}
                                                            </Link>
                                                        </span>,
                                                        t('code-name')
                                                    )}
                                                >
                                                    <Select
                                                        className="form-select"
                                                        size="large"
                                                        placeholder={t('choose-earlier')}
                                                        options={plxLoaded ? plxCompList : plxLoaded ? plxCompList : codeAndNameCompList}
                                                        disabled={plxLoaded ?
                                                            !plxCompList?.length :
                                                            plxLoaded ?
                                                            !plxCompList?.length :
                                                            !codeAndNameCompList?.length
                                                        
                                                        }
                                                    />
                                                </Form.Item>
                                                <Divider />

                                                <h5 className="text">{t('know')}</h5>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'plannedResults']}
                                                    label={t('planned-results')}
                                                    {...handleFieldEnableStatus(
                                                        !!toKnowTeachingResultsList?.length,
                                                        <span>
                                                            {`${t('fill-in-field-first-form')} 
                                                            "${t('planned-results')}" 
                                                            ${t('in-chapter')} `}
                                                            <Link
                                                                to={(location) => ({ ...location, hash: '#6.3' })}
                                                            >
                                                                {t('planned-results-edu')}
                                                            </Link>
                                                        </span>,
                                                        t('planned-results')
                                                    )}
                                                >
                                                    <Select
                                                        className="form-select"
                                                        size="large"
                                                        placeholder={t('one-of-earlier')}
                                                        options={toKnowTeachingResultsList}
                                                        disabled={!tabDetails?.criteria?.[key]?.codeName
                                                            || !toKnowTeachingResultsList?.length}
                                                    />
                                                </Form.Item>
                                                <p className="text">{t('criteria-for-evaluating')}</p>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'unsatisfactory']}
                                                    label={t('unsatisfactory')}
                                                >
                                                    <InputV2
                                                        textarea
                                                        autoSize={{ minRows: 2, maxRows: 4 }}
                                                        placeholder={t('type-criteria')}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'satisfactory']}
                                                    label={t('satisfactory')}
                                                >
                                                    <InputV2
                                                        textarea
                                                        autoSize={{ minRows: 2, maxRows: 4 }}
                                                        placeholder={t('type-criteria')}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'good']}
                                                    label={t('good')}
                                                >
                                                    <InputV2
                                                        textarea
                                                        autoSize={{ minRows: 2, maxRows: 4 }}
                                                        placeholder={t('type-criteria')}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'great']}
                                                    label={t('great')}
                                                >
                                                    <InputV2
                                                        textarea
                                                        autoSize={{ minRows: 2, maxRows: 4 }}
                                                        placeholder={t('type-criteria')}
                                                    />
                                                </Form.Item>
                                                <Divider />

                                                <h5 className="text">{t('be-able')}</h5>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'plannedResultsAble']}
                                                    label={t('planned-results-can')}
                                                    {...handleFieldEnableStatus(
                                                        !!toAbleTeachingResultsList?.length,
                                                        <span>
                                                            {`${t('fill-in-field-first-form')} 
                                                            "${t('planned-results-can')}" 
                                                            ${t('in-chapter')} `}
                                                            <Link
                                                                to={(location) => ({ ...location, hash: '#6.3' })}
                                                            >
                                                                {t('planned-results-edu')}
                                                            </Link>
                                                        </span>,
                                                        t('planned-results-can')
                                                    )}
                                                >
                                                    <Select
                                                        className="form-select"
                                                        size="large"
                                                        placeholder={t('one-of-earlier')}
                                                        options={toAbleTeachingResultsList}
                                                        disabled={!tabDetails?.criteria?.[key]?.codeName
                                                            || !toAbleTeachingResultsList?.length}
                                                    />
                                                </Form.Item>
                                                <p className="text">{t('criteria-for-evaluating')}</p>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'unsatisfactory2']}
                                                    label={t('unsatisfactory')}
                                                >
                                                    <InputV2
                                                        textarea
                                                        autoSize={{ minRows: 2, maxRows: 4 }}
                                                        placeholder={t('type-criteria')}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'satisfactory2']}
                                                    label={t('satisfactory')}
                                                >
                                                    <InputV2
                                                        textarea
                                                        autoSize={{ minRows: 2, maxRows: 4 }}
                                                        placeholder={t('type-criteria')}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'good2']}
                                                    label={t('good')}
                                                >
                                                    <InputV2
                                                        textarea
                                                        autoSize={{ minRows: 2, maxRows: 4 }}
                                                        placeholder={t('type-criteria')}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'great2']}
                                                    label={t('great')}
                                                >
                                                    <InputV2
                                                        textarea
                                                        autoSize={{ minRows: 2, maxRows: 4 }}
                                                        placeholder={t('type-criteria')}
                                                    />
                                                </Form.Item>
                                                <Divider />

                                                <h5 className="text">{t('own')}</h5>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'plannedResultsOwn']}
                                                    label={t('planned-results-own')}
                                                    {...handleFieldEnableStatus(
                                                        !!toOwnTeachingResultsList?.length,
                                                        <span>
                                                            {`${t('fill-in-field-first-form')} 
                                                            "${t('planned-results-own')}" 
                                                            ${t('in-chapter')} `}
                                                            <Link
                                                                to={(location) => ({ ...location, hash: '#6.3' })}
                                                            >
                                                                {t('planned-results-edu')}
                                                            </Link>
                                                        </span>,
                                                        t('planned-results-own')
                                                    )}
                                                >
                                                    <Select
                                                        className="form-select"
                                                        size="large"
                                                        placeholder={t('one-of-earlier')}
                                                        options={toOwnTeachingResultsList}
                                                        disabled={!tabDetails?.criteria?.[key]?.codeName
                                                            || !toOwnTeachingResultsList?.length}
                                                    />
                                                </Form.Item>
                                                <p className="text">{t('criteria-for-evaluating')}</p>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'unsatisfactory3']}
                                                    label={t('unsatisfactory')}
                                                >
                                                    <InputV2
                                                        textarea
                                                        autoSize={{ minRows: 2, maxRows: 4 }}
                                                        placeholder={t('type-criteria')}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'satisfactory3']}
                                                    label={t('satisfactory')}
                                                >
                                                    <InputV2
                                                        textarea
                                                        autoSize={{ minRows: 2, maxRows: 4 }}
                                                        placeholder={t('type-criteria')}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'good3']}
                                                    label={t('good')}
                                                >
                                                    <InputV2
                                                        textarea
                                                        autoSize={{ minRows: 2, maxRows: 4 }}
                                                        placeholder={t('type-criteria')}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'great3']}
                                                    label={t('great')}
                                                >
                                                    <InputV2
                                                        textarea
                                                        autoSize={{ minRows: 2, maxRows: 4 }}
                                                        placeholder={t('type-criteria')}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col offset={1} span={1}>
                                            <div className="item-action">
                                                <DeleteBtn
                                                    onClick={() => remove(name)}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                            <Row>
                                <Col span={22}>
                                    <Form.Item>
                                        <Row
                                            align="middle"
                                            justify="space-between"
                                        >
                                            <ButtonV2
                                                type="link"
                                                className={b('btn-indent')}
                                                onClick={() => add(emptyObj.criteria[0])}
                                                icon={<PlusOutlined />}
                                            >
                                                {t('add-more-block')}
                                            </ButtonV2>
                                        </Row>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}
                </Form.List>
            </Form>
            <Divider />

            <Next
                onPreview={() => previewDoc(tabKey, {
                    ...emptyObj,
                    ...tabDetails,
                }, dispatch)}
                onNextClick={() => onNextClick(form)}
            />
        </div>
    );
};

export default WorkProgramEvaluation;
