import React from 'react';
import { ButtonV2 } from 'components';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as CrossSmallIcon } from 'assets/icons/cross-small.svg';


const DeleteBtn = (props) => {
    const {
        icon,
        onClick,
        disabled,
        width,
        zIndex
    } = props;

    return (
        <ButtonV2
            type="link"
            style={{zIndex}}
            {...{ className: 'icon-button' }}
            {...(
                (disabled)
                    ? {
                        disabled: true,
                        className: 'icon-button-disabled'
                    }
                    : {
                        onClick
                    }
            )}
        >
            {
                icon === 'cross'
                    ? <CrossSmallIcon />
                    : <DeleteIcon className={width ? 'width' : ''} />
            }
        </ButtonV2>
    );
};

export default DeleteBtn;
