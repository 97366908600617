import React, { useEffect } from 'react';
import bem from 'easy-bem';
import { useTranslation } from 'react-i18next';
import { Divider, Form } from 'antd';
import useDebouncedCallback from 'utils/use-debounce-callback';
import { useDispatch, useSelector } from 'react-redux';
import { updateTabDetails } from 'utils/dispatches';
import { previewDoc } from '../../utils';
import OOCTextEditor from '../../../../../text-editor';
import Next from '../../../../../next';
import { emptyDocDetails } from '../../constants';
import './style.less';


const tabKey = '6.7';
const emptyObj = emptyDocDetails[tabKey];
const WorkProgramMaterial = ({ onNextClick }) => {
    const b = bem('work-material-evaluation');
    const { t } = useTranslation('work-material-evaluation');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const docDetails = useSelector((state) => state.documents.documentDetails);
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);

    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const rppList = useSelector((state) => state.documents.rppList);
    const currentRppIndex = useSelector((state) => state.documents.currentRppIndex);

    const onValuesChange = useDebouncedCallback((changed, all) => {
        const newTabDetails = {
            ...all,
        };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    useEffect(() => {
        const newObj = rppList[currentRppIndex]?.[tabKey] ? { ...tabDetails?.[tabKey] } : { ...emptyObj };
        form.setFieldsValue(newObj);
    }, [rppList?.length, currentRppIndex]);

    return (
        <div className={b()}>
            <div className="constructor-form">
                <h4 className="title m-24">{t('title')}</h4>
                <h4 className="program-title-name">
                    {currentRppIndex === -1 ? '' : `${t('rpp')} ${docDetails?.['6.1']?.workProgram} ${rppList[currentRppIndex]?.[6.1]?.type}`}
                </h4>
                <Form
                    form={form}
                    initialValues={initialValues}
                    onValuesChange={onValuesChange}
                    layout="vertical"
                >
                    <Form.Item
                        name="requirements"
                        label={t('requirements')}
                    >
                        <OOCTextEditor
                            height="610px"
                            placeholder={t('volume-requirements')}
                        />
                    </Form.Item>
                </Form>
                <Divider />
                <Next
                    onPreview={() => previewDoc(tabKey, {
                        ...emptyObj,
                        ...tabDetails,
                    }, dispatch)}
                    onNextClick={() => onNextClick(form)}
                />
            </div>
        </div>
    );
};

export default WorkProgramMaterial;
