/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import bem from 'easy-bem';
import {
    Form, Col, Row, AutoComplete
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import InputV2 from 'components/input-v2';
import ButtonV2 from 'components/button-v2';
import DeleteBtn from 'components/delete-btn';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import { Link } from 'react-router-dom';
import expandArr from 'utils/expandArr';
import Select from 'components/select';
import { useSelector } from 'react-redux';
import { GenBlock } from 'components/generated-block/generatedBlock';
import { useXlsxParser } from 'utils/excel-parse';
import useDebouncedCallback from 'utils/use-debounce-callback';

const tabKey = '1.4';

const ResultsUniversalCompFormBlock = ({ profCompEmptyObj, profComp = [], form }) => { // 1.4, блок 3
    const b = bem('results-prof-comp-form-block');
    const { t } = useTranslation('dev-educational-program');
    const { activityTasks, activityLaborFunc } = useSelector((state) => state.documents.documentDetails?.['1.3'] || {});
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey] || profCompEmptyObj);

    const xlsxParser = useXlsxParser();
    const xlsxLoaded = xlsxParser?.isLoaded();

    const listPK = useMemo(() => xlsxLoaded ? xlsxParser.getNamesCategoryPK() : [], [xlsxLoaded]);

    const activityTasksList = useMemo(
        () => expandArr(activityTasks || [], [
            'tasks'
        ])
            .filter(({ name }) => name)
            .map(({ name }) => ({
                label: name,
                value: name,
            })),
        [activityTasks]
    );
    const [isTasksDisabled, setTasksDisabled] = useState(false);
    useEffect(() => setTasksDisabled(!activityTasksList?.length), [activityTasksList]);

    const psCodeList = useMemo(
        () => expandArr(activityLaborFunc || [], [
            'codeAndNameOfPsOtfTf'
        ])
            .filter(({ psCode }) => psCode)
            .map(({ psCode, codeAndNameOfOtfTf = [] }) => ({
                label: psCode,
                value: psCode,
                option: expandArr(codeAndNameOfOtfTf, [
                    'laborFunctions'
                ])
                    .filter(({ code }) => code)
                    .map(({ code }) => ({
                        label: code,
                        value: code,
                    }))
            })),
        [activityLaborFunc]
    );

    const onCategoryChange = useDebouncedCallback((value, target, index) => {
        try {
            const details = { ...tabDetails };
            details.profComp[index].namePc = value;
            const { code, index: childIndex, parentIndex } = target;
            const list = xlsxParser.getCodeAndNameOfIndicator(code, childIndex, parentIndex);
            details.profComp[index].list = list?.length ? list : profCompEmptyObj.list;
            form.setFieldsValue(details);
        } catch (err) {
            console.log(err);
        }
    }, 500);

    const codeTfLists = useMemo(
        () => (profComp || [])
            .map(({ baseDefinitionPc = [] }) => baseDefinitionPc
                .map(({ name }) => (psCodeList || [])
                    .find(({ value }) => value === name)
                    ?.option)),
        [profComp, psCodeList]
    );

    return (
        <section className={b()}>
            <h5 className="subtitle">
                {`${t('block-3')}: ${t('prof-comp')}`}
            </h5>
            <Form.List name="profComp">
                {(fields, { add, remove }) => (
                    <>
                        <div className="form-items-list">
                            {fields.map(({
                                key, name, ...restField
                            }, index) => (
                                <div className={`${b('comp-item')} form-items-group`} key={key}>
                                    <Row
                                        gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                        align="bottom"
                                        wrap={false}
                                    >
                                        <Col flex="1">
                                            <div className="item-block-group">
                                                <Row>
                                                    <Col flex="1">
                                                        <Form.Item
                                                            label={t('pc-professional-activity-task')}
                                                            name={[name, 'tasks']}
                                                            {...handleFieldEnableStatus(
                                                                !!activityTasksList?.length,
                                                                <span>
                                                                    {`${t('fill-in-field-first')} 
                                                                    "${t('activity-prof-task')}" 
                                                                    (${t('chapter')} `}
                                                                    <Link
                                                                        to={(location) => ({
                                                                            ...location,
                                                                            hash: '#1.3'
                                                                        })}
                                                                    >
                                                                        {t('characteristic-prof-activity')}
                                                                    </Link>
                                                                    {`, ${t('block-2')})`}
                                                                </span>,
                                                                t('professional-activity-tasks'),
                                                            )}
                                                            {...restField}
                                                        >
                                                            <Select
                                                                className="form-select"
                                                                size="large"
                                                                mode="multiple"
                                                                showSearch
                                                                notFoundContent={t('not-found-content-text')}
                                                                placeholder={t('choose-pc-professional-activity-task')}
                                                                options={activityTasksList}
                                                                disabled={isTasksDisabled}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col flex="1">
                                                        <Form.Item
                                                            {...restField}
                                                            label={t('prof-comp-code-pc-name', {
                                                                index: name + 1
                                                            })}
                                                            name={[name, 'namePc']}
                                                        >
                                                            <AutoComplete
                                                                className="form-select"
                                                                placeholder={t('enter-pc-name')}
                                                                size="large"
                                                                showSearch
                                                                onChange={(value, target) => onCategoryChange(value, target, index)}
                                                                options={listPK}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <p className="item-label">
                                                    {t('pc-achievement-indicator-сode-and-name')}
                                                </p>
                                                <div className="item-block-group-3">
                                                    <GenBlock
                                                        blockFieldName={name}
                                                        blockName="list"
                                                        initialValueAdd={profCompEmptyObj.list[0]}
                                                        {...restField}
                                                    >
                                                        <Row
                                                            gutter={[32, 8]}
                                                            justify="space-between"
                                                        >
                                                            <Col flex="136px">
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'indicatorCode']}
                                                                >
                                                                    <InputV2
                                                                        placeholder={t('enter-pc-indicator-code')}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col flex="1 0 247px">
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'indicatorName']}
                                                                >
                                                                    <InputV2
                                                                        textarea
                                                                        autoSize={{ minRows: 2, maxRows: 3 }}
                                                                        placeholder={t('enter-pc-indicator-name')}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </GenBlock>
                                                </div>

                                                <p className="item-label">Основание определения ПК</p>
                                                <Form.List name={[name, 'baseDefinitionPc']}>
                                                    {(fields, { add, remove }) => (
                                                        <>
                                                            <div className="form-items-list">
                                                                {fields.map(({
                                                                    key, name: baseName, ...restField
                                                                }) => (
                                                                    <div className="form-items-group" key={key}>
                                                                        <Row
                                                                            gutter={4}
                                                                            align="bottom"
                                                                            wrap={false}
                                                                        >
                                                                            <Col flex="1">
                                                                                <Row
                                                                                    gutter={
                                                                                        [{ sm: 16, md: 24, lg: 32 }, 16]
                                                                                    }
                                                                                    align="bottom"
                                                                                    wrap
                                                                                >
                                                                                    <Col span={24}>
                                                                                        <Form.Item
                                                                                            name={[baseName, 'name']}
                                                                                            {...restField}
                                                                                        >
                                                                                            <AutoComplete
                                                                                                className="form-select"
                                                                                                size="large"
                                                                                                placeholder={t('enter-pc-definition-basis')}
                                                                                                options={psCodeList}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </Col>

                                                                                    {!!codeTfLists?.[name]?.[baseName]?.length && (
                                                                                        <Col flex="1 0 247px">
                                                                                            <Form.Item
                                                                                                name={[baseName, 'codeTf']}
                                                                                                label="Код ТФ"
                                                                                                {...restField}
                                                                                            >
                                                                                                <Select
                                                                                                    className="form-select"
                                                                                                    size="large"
                                                                                                    options={codeTfLists?.[name]?.[baseName]}
                                                                                                    placeholder="Выберите код ТФ"
                                                                                                />
                                                                                            </Form.Item>
                                                                                        </Col>
                                                                                    )}
                                                                                </Row>
                                                                            </Col>
                                                                            <Col>
                                                                                <Form.Item>
                                                                                    <DeleteBtn
                                                                                        icon="cross"
                                                                                        onClick={() => remove(baseName)}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <Row justify="end">
                                                                <Form.Item>
                                                                    <ButtonV2
                                                                        type="link"
                                                                        onClick={() => add(profCompEmptyObj.baseDefinitionPc[0])}
                                                                        icon={<PlusOutlined />}
                                                                    >
                                                                        {t('add-more')}
                                                                    </ButtonV2>
                                                                </Form.Item>
                                                            </Row>
                                                        </>
                                                    )}
                                                </Form.List>
                                            </div>
                                        </Col>
                                        <Col>
                                            <Form.Item>
                                                <DeleteBtn
                                                    onClick={() => remove(name)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                        <Form.Item>
                            <ButtonV2
                                type="link"
                                onClick={() => add(profCompEmptyObj)}
                                icon={<PlusOutlined />}
                            >
                                {t('add-more-block')}
                            </ButtonV2>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </section>
    );
};

export default ResultsUniversalCompFormBlock;
