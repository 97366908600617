import React, { ReactNode } from 'react';
import { XLSXParser } from './excel/Excel';
import { PlxParser } from './plx/Plx';

const plxType = 'plx';
const excelType = 'excel';

export interface XLSXParserProps {
  reset: () => void;
  setPagesData: (data: any) => void;
  loadXlsx: (xmlTest: any, details?: any, fileId?: any) => any;
  loadParsingData: (xmlTest: any, details?: any, fileId?: any) => any;
  isLoaded: () => boolean;
  getFileType: () => string;
  getFounderOfTheUniversity: () => string;
  getDisciplineModules: () => any;
  getCodeAndNameDisciplineModule: () => any;
  getTypeOfPractice: () => any;
  getTypeOfPracticeRpp: () => any;
  getWorkingProgram: (typeOfPractice: string) => any;
  getNamesCategoryUK: () => Array<any>;
  getNamesCategoryOPK: () => Array<any>;
  getNamesCategoryPK: () => Array<any>;
  getCompetencyAndName: (data: any) => any;
  getCompetencyAndNameRpp: (data: any) => any;
  getCompetencyAndName6: (data: any, value: any) => any;
  getCoursesAndSemesters: (data: any) => any;
  getCoursesAndSemesters6: (data: any) => any;
  getFormOfIntermediateCertification: (data: any) => any;
  getCodeAndNameOfIndicator: (code: string, index: number, parentIndex: number) => any;
  getProfArea: () => any;
  getProfSpheres: () => any;
  getProfActivity: () => any;
  getCodeAndNameOfProf: () => any;
  getCourses: (codeAndName: string) => any;
  getNameOfDicipline: (name: string) => any;
  getCoursesRPP: (practiceType: string, typeOfPractice: string) => any;
  getDurationInWeeks: (practiceType: string) => any;
  getSemester: (codeAndName: string, course: string) => any;
  getSemestersRPP: (typeOfPractice: string, practiceType: string) => any;
  getContactWorkEx: (
    codeAndName: string,
    course: string,
    semester: string
  ) => any;
  getContactWorkExHours: (
    codeAndName: string,
    course: string,
    semester: string
  ) => any;
  getNonAuditHours : (
    codeAndName: string,
    course: string,
    semester: string
  ) => any;
  getContactWorkExRPP: (practiceType: string) => any;
  getIndependentWork: (
    codeAndName: string,
    course: string,
    semester: string
  ) => any;
  getIntermediateCertification: (
    codeAndName: string,
    course: string,
    semester: string
  ) => any;
  getIntermediateCertificationRPP: (practiceType: string) => any;
  getContactWork: (
    codeAndName: string,
    course: string,
    semester: string
  ) => any;
  getTotalVolumeOfTheDisciplineModule:(codeAndName: string, course: string, semester: string) => number;
  getLectures: (codeAndName: string, course: string, semester: string) => any;
  getSeminar: (codeAndName: string, course: string, semester: string) => any;
  getTypeOfTrainingSession: (
    codeAndName: string,
    course: string,
    semester: string
  ) => any;
  getPracticeType: () => any;
  getTotalScopeInHours: (practiceType: string) => any;
  getRppCourse: (practiceType: string) => any;
  getGeneralCreditUnits: (practiceType: string) => any;
  getRppSemester: (
    practiceType: string,
    typeOfPractice: string,
    course: string
  ) => any;
  getRppCompetenceCodeAndName: (practiceType: string) => any;
  getBranches: () => any;
  res: () => any;
  clearForm: (details: any) => any;
}

export const Parser = (file: string) => {
  let fileType = file || '';

  const plxParser = PlxParser();
  const xlsParser = XLSXParser();

  const loadParsingData = async (parsingData: string, details: any = {}, filetype: any) => {
    if (filetype === plxType) {
      fileType = plxType;
      return plxParser.loadParsingData(parsingData, details);
    }
    
    if (filetype === excelType) {
      fileType = excelType;
      return await xlsParser.loadParsingData(parsingData, details);
    }
  };

  const setPagesData = (data: any = {}) => {
    if (data.fileType === plxType) {
      plxParser.setPagesData(data);
      fileType = plxType;
    }

    if (data.fileType === excelType) {
      xlsParser.setPagesData(data);
      fileType = excelType;
    }
  };

  
  const isLoaded = () => false;
  
  const getFileType = () => fileType;

  if (fileType === plxType) {
    return { ...plxParser, getFileType, loadParsingData, setPagesData }
  }

  if (fileType === excelType) {
    return { ...xlsParser, getFileType, loadParsingData, setPagesData }
  }

  return { loadParsingData, setPagesData, isLoaded };
};