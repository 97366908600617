import React from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import bem from 'easy-bem';
// import { useLocation, Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import './style.less';


const { Item } = Breadcrumb;

const OOCBreadcrumbs = () => {
    const b = bem('ooc-breadcrumbs');
    const { t } = useTranslation('breadcrumbs');
    const location = useLocation();

    // eslint-disable-next-line consistent-return
    const getPathArray = () => {
        const { pathname } = location;
        const path = pathname.slice(1);

        switch (path) {
            case 'involvement-heatmap': {
                return [t('consortium'), t(path)];
            }
            case 'ooc-actualization': {
                return [t('educational-programs'), t(path)];
            }
            case 'study-control': {
                return [t('education-control'), t(path)];
            }
            case 'universities-rating': {
                return [t('education-control'), t(path)];
            }
            case 'consortium-participants': {
                return [t('consortium'), t(path)];
            }
            case 'personal':
            case '/':
            default: {
                break;
            }
        }
    };

    const constructBreadcrumbs = () => {
        const pathArray = getPathArray();
        return pathArray?.map((pathName) => (
            <Item key={pathName}>
                {pathName}
            </Item>
        ));
    };

    return (
        <div className={b()}>
            <Breadcrumb separator={<RightOutlined />}>
                { constructBreadcrumbs() }
            </Breadcrumb>
        </div>
    );
};

export default OOCBreadcrumbs;
