/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import bem from 'easy-bem';
import {
    Form, Divider, Row, Col
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ButtonV2 from 'components/button-v2';
import { PlusOutlined } from '@ant-design/icons';

import useDebouncedCallback from 'utils/use-debounce-callback';
import Next from 'components/next';
import DeleteBtn from 'components/delete-btn';
import Select from 'components/select';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import { Link } from 'react-router-dom';
import { updateTabDetails } from 'utils/dispatches';
import { usePlxParser } from 'utils/plx';
import { previewDoc } from '../../utils';
import WorkPlannedResultsIndicatorCodeNameSubformBlock from './indicatorCodeName';
import { emptyDocDetails } from '../../constants';
import { getDocDetails } from 'models/documents/selectors';
import { useXlsxParser } from 'utils/excel-parse';


const tabKey = '5.3';
const emptyObj = emptyDocDetails[tabKey];

const WorkPlannedResultsTab = ({ onNextClick }) => {
    const b = bem('work-planned-results-tab');
    const { t } = useTranslation('dev-educational-program');
    const docDetails = useSelector(getDocDetails);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);
    const { results = [] } = tabDetails || {};

    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);
    const [isCodeAndNameDisabled, setCodeAndNameDisabled] = useState(false);
    const xlsxParser = useXlsxParser();
    const xlsxLoaded = xlsxParser?.isLoaded();

    const rpdList = useSelector((state) => state.documents.rpdList);
    const currentRpdIndex = useSelector(
        (state) => state.documents.currentRpdIndex
    );

    const competencyAndName = useMemo(() => {
        if (xlsxLoaded) {
            return xlsxParser?.getCompetencyAndName(docDetails[5.1]?.disciplineCodeAndName)
            ?.map((item) => ({
                label: item,
                value: item,
            })) || [];
        }
        return []
    }, [xlsxLoaded]);

    const {
        universalComp = [],
        commonComp = [],
        profComp = [],
    } = useSelector((state) => state.documents.documentDetails?.[1.4] || {});
    const codeAndNameList = useMemo(
        () => [
            ...(universalComp || [])
                .filter(({ codeName }) => codeName)
                .map(({ codeName }) => ({
                    label: codeName,
                    value: codeName,
                })),
            ...(commonComp || [])
                .filter(({ codeName }) => codeName)
                .map(({ codeName }) => ({
                    label: codeName,
                    value: codeName,
                })),
            ...(profComp || [])
                .filter(({ codeName }) => codeName)
                .map(({ codeName }) => ({
                    label: codeName,
                    value: codeName,
                })),
        ],
        [commonComp, profComp, universalComp]
    );

    useEffect(() => {
        setCodeAndNameDisabled(!codeAndNameList?.length);
    }, [codeAndNameList]);

    const onFieldsChange = useDebouncedCallback((changedFields) => {
        const newTabDetails = { ...form.getFieldsValue() };
        // eslint-disable-next-line no-restricted-syntax
        for (const changedField of changedFields) {
            const { name } = changedField;
            if (name[0] === 'results' && name[2] === 'codeAndName') {
                newTabDetails.results[name[1]].list = newTabDetails
                    .results[name[1]].list
                    .map(({ codeAndNameIndicator, ...rest }) => ({
                        codeAndNameIndicator: '',
                        ...rest
                    }));
            }
        }
        form.setFieldsValue(newTabDetails);
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    useEffect(() => {
        const newObj = rpdList[currentRpdIndex]?.[tabKey] ? { ...tabDetails?.[tabKey] } : { ...emptyObj };
        form.setFieldsValue(newObj);
    }, [rpdList?.length, currentRpdIndex]);
    return (
        <div className={b()}>
            <div className="constructor-form">
                <h4 className="title">{t('work-planned-results')}</h4>
                <h4 className="program-title-name">
                    {currentRpdIndex === -1
                        ? ''
                        : `${t('rpd')}: ${rpdList[currentRpdIndex]?.[5.1].disciplineCodeAndName}`}
                </h4>
                <p>
                    Процесс изучения дисциплины(модуля) направлен на формирование
                    следующих компетенций:
                </p>
                <Form
                    form={form}
                    initialValues={initialValues}
                    onFieldsChange={onFieldsChange}
                    layout="vertical"
                >
                    <section>
                        <Form.List name="results">
                            {(fields, { add, remove }) => (
                                <>
                                    <div className="form-items-list">
                                        {fields.map(({
                                            key, name, ...restField
                                        }, index) => (
                                            <div className={`${b('task-item')} form-item`} key={key}>
                                                <Row
                                                    gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                                    align="bottom"
                                                    wrap={false}
                                                >
                                                    <Col flex="1">
                                                        <div className="item-block-group">
                                                            <Row>
                                                                <Col flex="1">
                                                                    <Form.Item
                                                                        label="Код и наименование компетенции"
                                                                        name={[name, 'codeAndName']}
                                                                        {...handleFieldEnableStatus(
                                                                            !!codeAndNameList?.length,
                                                                            <span>
                                                                                {`${t('fill-in-field-first')}
                                                                                "${t(
                                                'uc-name'
                                            )}",
                                                                                "${t(
                                                'cc-name'
                                            )}"
                                                                                или
                                                                                "${t(
                                                'pc-name'
                                            )}"
                                                                                (${t(
                                                'module'
                                            )}
                                                                                "${t(
                                                'opop-general-characteristics'
                                            )}",
                                                                                ${t(
                                                'chapter'
                                            )} `}
                                                                                <Link
                                                                                    to={(location) => ({
                                                                                        ...location,
                                                                                        hash: '#1.4'
                                                                                    })}
                                                                                >
                                                                                    {t(
                                                                                        'educational-programs-development-results'
                                                                                    )}
                                                                                </Link>
                                                                                {')'}
                                                                            </span>,
                                                                            'Код и наименование компетенции'
                                                                        )}
                                                                        {...restField}
                                                                    >
                                                                        <Select
                                                                            className="form-select"
                                                                            size="large"
                                                                            showSearch
                                                                            notFoundContent={t(
                                                                                'not-found-content-text'
                                                                            )}
                                                                            placeholder="Выберите код и наименование компетенции"
                                                                            options={
                                                                                (xlsxLoaded && competencyAndName.length)
                                                                                    ? competencyAndName
                                                                                    : codeAndNameList
                                                                            }
                                                                            disabled={
                                                                                xlsxLoaded
                                                                                    ? !competencyAndName.length
                                                                                    : isCodeAndNameDisabled
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>

                                                            <WorkPlannedResultsIndicatorCodeNameSubformBlock
                                                                fieldName={name}
                                                                listEmptyObj={emptyObj.results[0].list[0]}
                                                                selectedCodeAndName={
                                                                    results?.[name]?.codeAndName
                                                                }
                                                                codeAndName={results[index]?.codeAndName}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <Form.Item>
                                                            <DeleteBtn onClick={() => remove(name)} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))}
                                    </div>
                                    <Form.Item>
                                        <ButtonV2
                                            type="link"
                                            onClick={() => add(emptyObj.results[0])}
                                            icon={<PlusOutlined />}
                                        >
                                            {t('add-more-block')}
                                        </ButtonV2>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </section>

                    <Divider />

                    <Next
                        onPreview={() => previewDoc(tabKey, {
                            ...emptyObj,
                            ...tabDetails,
                        }, dispatch)}
                        onNextClick={() => onNextClick(form)}
                    />
                </Form>
            </div>
        </div>
    );
};

export default WorkPlannedResultsTab;
