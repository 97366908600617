import { getUnioneToken } from '@unione-pro/unione.commons.webpack';
import axios from 'axios';


const url = process.env.REACT_APP_BACKEND_SERVISE;

export const getUserList = async () => {
    const token = await getUnioneToken();
    return axios.get(`${url}/user`, {
        headers: {
            Authorization: `${token}`,
        }
    });
};
