export const expandArr = (arr = [], innerKeys = []) => {
    let temp = [];
    let arr2 = [...arr];

    for (const key of innerKeys) {
        temp = [
            ...arr2.reduce((prev, curr) => [...prev, ...(curr?.[key] || [])], []),
        ];
        arr2 = temp;
    }

    return temp.filter((x) => !!x);
};

export default expandArr;
