import React, { useEffect, useState } from 'react';
import bem from 'easy-bem';
import { useTranslation } from 'react-i18next';
import { Col, Row, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDebounce } from 'react-use';

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as NoResultsImage } from 'assets/images/opop-constructor/no-results.svg';
import { useDispatch, useSelector } from 'react-redux';

import ButtonV2 from 'components/button-v2';
import InputV2 from 'components/input-v2';
import _pick from 'lodash/pick';

import './style.less';
import {
    setCurrentDocDetails, setCurrentRpdIndex, setRpdList
} from 'models/documents/actions';
import { getDocDetails } from 'models/documents/selectors';
import { omit } from 'lodash';
import { Link } from 'react-router-dom';
import DeleteModal from '../work-saved-practices-tab/components/deleteModal';
import { generateAndDownloadDocument } from '../../utils';
import DeleteBtn from '../../../../../delete-btn';
import { emptyDocDetails, importDependencies } from '../../constants';
import DownloadBtn from '../../../../../download-btn';


const tabKey = '5.0';

const has = (str, query) => str.toLowerCase().search(query) !== -1;

const content = {
    title: 'Удаление рабочей программы дисциплин',
    confirmText: 'Вы действительно хотите удалить рабочую программу дисциплин',
    confirmSubText: 'РПД будет навсегда удалена.'
};

const moduleKeys = ['5.1', '5.2', '5.3', '5.4', '5.5', '5.6', '5.7', '5.8', '5.9', '5.10'];

const emptyTitleObject = {
    ...emptyDocDetails['5.1'],
    ...importDependencies['5.1'],
    developmentType: 'дисциплины',
};


const WorkSavedProgramsTab = ({ onNextClick }) => {
    const b = bem('work-saved-programs-tab');
    const { t } = useTranslation('dev-educational-program');
    const [searchValue, setSearchValue] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [programsList, setProgramsList] = useState([]);
    const [indexofModal, setIndexofModal] = useState(0);
    const rpdList = useSelector((state) => state.documents.rpdList);
    const docDetails = useSelector(getDocDetails);
    const dispatch = useDispatch();
    useDebounce(
        () => {
            if (searchValue) {
                const trimQuery = searchValue.trim().toLowerCase();

                if (trimQuery?.length > 0) {
                    setProgramsList(
                        rpdList.filter((rdp) => (rdp?.['5.1']?.disciplineCodeAndName
                            ? has(rdp['5.1'].disciplineCodeAndName, trimQuery)
                            : false))
                    );
                }
            } else {
                setProgramsList(rpdList);
            }
        },
        500,
        [searchValue]
    );

    useEffect(() => {
        setProgramsList(rpdList);
    }, [rpdList]);

    const onItemClick = (item, index) => () => {
        dispatch(setCurrentRpdIndex(index));
        const newDocDetails = JSON.parse(JSON.stringify(docDetails));
        moduleKeys.forEach((moduleKey) => {
            delete newDocDetails[moduleKey];
        });
        dispatch(setCurrentDocDetails({
            ...newDocDetails,
            ..._pick(rpdList[index], moduleKeys)
        }));
        onNextClick(tabKey);
    };

    const cleaningFromsData = () => {
        const newDocDetails = JSON.parse(JSON.stringify(docDetails));
        moduleKeys.forEach((item) => {
            delete newDocDetails[item];
        });
        dispatch(
            setCurrentDocDetails({
                ...newDocDetails,
                5.1: { ...emptyTitleObject }
            })
        );
    };

    const download = async (e, index) => {
        e.stopPropagation();
        let rpdCopy = { ...rpdList[index]};
        if (rpdCopy?.[1.1]) {
            delete rpdCopy?.[1.1];
        }
        const newDocDetails = { ...omit(docDetails, moduleKeys), ...rpdCopy };
        generateAndDownloadDocument(
            newDocDetails,
            moduleKeys,
            `${newDocDetails?.['5.1']?.disciplineCodeAndName?.replaceAll('.', '_')?.replaceAll('/', '-') || ''}`
        );
    };

    const showModal = (e, index) => {
        setIsModalVisible(true);
        setIndexofModal(index);
        e.stopPropagation();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOk = () => {
        const list = [...rpdList];
        list.splice(indexofModal, 1);
        cleaningFromsData();
        dispatch(setRpdList(list));
        dispatch(setCurrentRpdIndex(-1));
        setIsModalVisible(false);
    };

    const createNew = () => {
        cleaningFromsData();
        dispatch(setCurrentRpdIndex(-1));
        onNextClick();
    };

    if (!rpdList?.length) {
        return (
            <div className={b()}>
                <div className={b('header')}>
                    <h4 className={b('title')}>{t('work-saved-programs')}</h4>
                </div>

                <Row gutter={32} justify="space-between">
                    <Col span={12}>
                        <h5 className={b('subtitle')}>
                            В данной образовательной программе еще нет сохраненных РПД.
                        </h5>
                        <h5 className={b('subtitle')}>
                            {'РПД отобразится на этой странице после заполнения и сохранения '}
                            <Link
                                to={(location) => ({ ...location, hash: '#5.1' })}
                            >
                                Титульной информации
                            </Link>
                        </h5>
                    </Col>
                    <Col span={12}>
                        <NoResultsImage />
                    </Col>
                </Row>
            </div>
        );
    }

    return (
        <div className={b()}>
            <div className={b('header')}>
                <h4 className={b('title')}>{t('work-saved-programs')}</h4>
                {rpdList?.length < 100 ? (
                    <ButtonV2
                        onClick={createNew}
                        type="link"
                        icon={<PlusOutlined style={{ fontSize: '16px' }} />}
                    >
                        Создать новую РПД
                    </ButtonV2>
                ) : null}
            </div>

            <div className={b('search-block')}>
                <InputV2
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Поиск по коду или наименованию сохраненных РПД"
                    size="large"
                    suffix={<SearchIcon className="search-icon" />}
                />
            </div>

            <h5 className={b('subtitle')}>Обязательная часть</h5>

            <div className={b('programs-list')}>
                {programsList.map((item, index) => (
                    <div
                        aria-hidden="true"
                        key={`item-${item?.id}`}
                        onClick={onItemClick(item, index)}
                        className={b('program-item')}
                    >
                        <div className={b('item-title')}>{item?.['5.1']?.disciplineCodeAndName}</div>
                        <Row gutter={24} align="middle">
                            <Col flex="1">
                                <div className={b('item-status')}>
                                    <div className={`${b('status-icon')} circle ${item?.status}`} />
                                    <div className={b('status-text')}>{t(item?.status)}</div>
                                </div>
                            </Col>
                            <Col>
                                <DownloadBtn
                                    onClick={(e) => download(e, index)}
                                />
                            </Col>
                            <Col>
                                <DeleteBtn
                                    onClick={(e) => showModal(e, index)}
                                />
                            </Col>
                        </Row>
                    </div>
                ))}
            </div>

            <Divider />

            <DeleteModal
                isModalVisible={isModalVisible}
                directionCodeOrName={programsList[indexofModal]?.['5.1']?.disciplineCodeAndName}
                handleCancel={handleCancel}
                content={content}
                handleOk={handleOk}
                b={b}
            />
            {/* <h5 className={b('subtitle')}>
                Часть, формируемая участниками образовательных отношений
            </h5>


            <Divider />

            <h5 className={b('subtitle')}>
                Дисциплины (модули) по выбору
            </h5>

            <Divider /> */}
        </div>
    );
};

export default WorkSavedProgramsTab;
